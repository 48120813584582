import React, { useState, useEffect } from "react";
import "../../Css/Admin/StudentRequest.css";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Admin/header";
import axios from "axios";
import Port from "../../port";
import Icon from "../../Assets/request-message.svg";

function StudentsRequests() {
  const [found, setFound] = useState("");
  const [details, setDetails] = useState([]);
  const token=localStorage.getItem("token")
   
  const time = new Date().getHours();
  let greeting;
  if (time < 12) {
    greeting = "Good Morning!";
  } else if (12 < time < 16) {
    greeting = "Good Afternoon!";
  } else if (16 < time < 18) {
    greeting = "Good Evening!";
  } else {
    greeting = "Good Night!";
  }

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/student/requests`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setDetails(res.data);
         
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const requests = details.filter((data) => {
    return (
      data.n_o_p_number.toLowerCase().includes(found.toLowerCase()) ||
      data.mobile.toLowerCase().includes(found.toLowerCase()) ||
      data.name.toLowerCase().includes(found.toLowerCase())
    );
  });

  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}
          <div className="student-request-status-wrapper clearfix">
            <div>
              <input
                type="search"
                placeholder="Search Name or NIC..."
                className="student-request-search"
                onChange={(event) => {
                  setFound(event.target.value);
                }}
              />
            </div>
            <div className="student-request-status">
              <div className="student">
                <FiberManualRecordIcon
                  fontSize="small"
                  style={{ color: "rgb(239, 129, 10)" }}
                />
              </div>
              <p
                style={{ marginLeft: "5px", fontSize: "14px", color: "black" }}
              >
                Pending
              </p>
            </div>
          </div>
          <div className="student-request-table-wrapper">
            <div className="student-request-table-header">
              <div id="student-request-body-col1">
                <center> STATUS</center>
              </div>
              <div id="student-request-body-col6">
                <center> COURSE</center>
              </div>
              <div id="student-request-body-col2">
                <center>PASS or NIC</center>
              </div>
              <div id="student-request-body-col3">
                <center> NAME</center>
              </div>
              <div id="student-request-body-col4">
                <center> CONTACT</center>
              </div>
              <div id="student-request-body-col5">
                <center> ACTION</center>
              </div>
            </div>
            {details.length !== 0 ? (
              <div>
                {requests.map((detail, index) => (
                  <StudentsRequestsTable
                    key={index}
                    ID={detail.id}
                    course={detail.i_course}
                    NIC={detail.n_o_p_number}
                    Name={detail.f_name}
                    contact={detail.mobile}
                  />
                ))}
              </div>
            ) : (
              <div className="empty-message-container">
                <div className="empty-message-detail-wrapper">
                  <center>
                    <img src={Icon} width="50px" alt="request icon" />
                    <h4 className="empty-message-header">{greeting}</h4>
                    <p className="empty-message">
                      There're no any requests to show!
                    </p>
                  </center>
                </div>
              </div>
            )}
          </div>
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

export default StudentsRequests;

function StudentsRequestsTable(props) {
  const navigate = useNavigate();

  const detailsHandler = () => {
    navigate(`/student-request/details/id-${props.ID}`);
  };
  const viewHandler = () => {
    navigate(`/student-request/details/id-${props.ID}`);
  };

  return (
    <div className="student-request-table-body" onClick={detailsHandler}>
      <div className="student-request-body-col1">
        <center>
          {" "}
          <FiberManualRecordIcon
            fontSize="small"
            style={{ color: "rgb(239, 129, 10)" }}
          />
        </center>
      </div>
      <div className="student-request-body-col6">
        <center> {props.course}</center>
      </div>
      <div className="student-request-body-col2">
        <center> {props.NIC}</center>
      </div>
      <div className="student-request-body-col3">
        <center> {props.Name}</center>
      </div>
      <div className="student-request-body-col4">
        <center> {props.contact}</center>
      </div>
      <div className="student-request-body-col5">
        <center>
          {" "}
          <RemoveRedEyeOutlinedIcon
            fontSize="small"
            style={{ color: "rgb(239, 129, 10)" }}
            onClick={viewHandler}
            className="view-request-btn"
            titleAccess="More Details.."
          />
        </center>
      </div>
    </div>
  );
}
