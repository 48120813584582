import React, { useState, useEffect } from "react";
import "../../Css/Admin/request-details.css";
import MonNavBar from "./mob_nav_bar";
import AdminNavBar from "./admin_nav_bar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import PortraitIcon from "@mui/icons-material/Portrait";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Alerts from "./Alert";
import FileDownload from "js-file-download";
import Header from "./header";
import Port from "../../port.js";
import axios from "axios";

function ApprovedDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [detail, setDetails] = useState({});
   const [active, setActive] = useState(false);
   const [ertype, setErtype] = useState();
   const [ermes, setErmes] = useState();
  let nic = detail.n_o_p_number;
  const token=localStorage.getItem("token")

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/approved/request/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, token]);
  
const profileImageHandler = () => {
  axios({
    url: `http://${Port}/admin/student/profile/nic-${nic}`,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.data) {
        // setOpen(false);
        const file = new Blob([res.data], { type: "image/jpg" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
    .catch((err) => {
      if (err) {
        setActive(true);
        setErmes("Something went wrong!");
        setErtype("error");
      }
    });
};

const NICimageHandler = () => {
  axios({
    url: `http://${Port}/admin/student/nic/nic-${nic}`,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.data) {
        // setOpen(false);
        const file = new Blob([res.data], { type: "image/jpg" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
    .catch((err) => {
      if (err) {
        setActive(true);
        setErmes("Something went wrong!");
        setErtype("error");
      }
    });
};
const eductionFilesHandler = () => {
  axios({
    url: `http://${Port}/admin/student/edu-detail/nic-${nic}`,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.data) {
        FileDownload(res.data, `${nic}.zip`);
      }
    })
    .catch((err) => {
      if (err) {
        setActive(true);
        setErmes("Something went wrong!");
        setErtype("error");
      }
    });
  };
  const workFileFilesHandler = () => {
    axios({
      url: `http://${Port}/admin/student/work-file/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          FileDownload(res.data, `${nic}.zip`);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };

const paymentSlipHandler = () => {
  axios({
    url: `http://${Port}/admin/student/payment-slip/nic-${nic}`,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.data) {
        // setOpen(false);
        const file = new Blob([res.data], { type: "image/jpg" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
    .catch((err) => {
      if (err) {
        setActive(true);
        setErmes("Something went wrong!");
        setErtype("error");
      }
    });
};
  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MonNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}
          <div className="body-details-wrapper clearfix">
            {active && (
              <Alerts message={ermes} type={ertype} setActive={setActive} />
            )}
            <ArrowBackIcon
              className="back-btn"
              onClick={() => {
                navigate(-1);
              }}
            />
            <ReceiptIcon
              className="action-icons"
              titleAccess="View payment slip"
              onClick={paymentSlipHandler}
            />

            <PortraitIcon
              className="action-icons"
              titleAccess="View student's NIC"
              onClick={NICimageHandler}
            />

            <FileDownloadIcon
              className="action-icons"
              titleAccess="Download work files"
              onClick={workFileFilesHandler}
            />
            <FileDownloadIcon
              className="action-icons"
              titleAccess="Download educational files"
              onClick={eductionFilesHandler}
            />

            <div className="student-request-details-header-wrapper">
              <div className="student-request-id">
                Request ID : <b>{detail.id ? detail.id : "-"}</b>
              </div>
              <div className="student-image">
                <img
                  src={`http://${Port}/profile/${nic}.jpg`}
                  alt="Profile"
                  className="student-profile-icon"
                  onClick={profileImageHandler}
                />
              </div>
              <div className="student-request-timedate">
                Requested Date : <b>{detail.s_date ? detail.s_date : "-"}</b>
              </div>
            </div>
            <div className="student-request-details-body-wrapper ">
              {/* basic informations below */}
              <div className="student-request-details-topic">
                - Basic Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper ">
                    <p className="student-question">Full Name </p>
                    <p className="student-question">Name with Initials </p>
                    <p className="student-question">Date of Birth </p>
                    <p className="student-question">Gender </p>
                    <p className="student-question">County </p>
                    <p className="student-question">
                      {detail.nic_o_passport ? detail.nic_o_passport : "-"}
                    </p>
                    <p className="student-question">MS Teams ID </p>
                    <p className="student-question">Per Address </p>
                    <p className="student-question">
                      Corr Address
                      {/* <br />
                      <span className="question">Correspondence Address</span> */}
                    </p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.f_name ? detail.f_name : "-"}
                      {/* <br />
                      <span className="answer">
                        Mahabalage Don Thivanka Saparamaduuuuuuuuuuuuu
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.name ? detail.name : "-"}
                      {/* <br />
                      <span className="answer">
                        Mahabalage Don Thivanka Saparamaduuuuuuuuuuuuu
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.d_o_birth ? detail.d_o_birth : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.gender ? detail.gender : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.citizenship ? detail.citizenship : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.n_o_p_number ? detail.n_o_p_number : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.ms_id ? detail.ms_id : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.p_address ? detail.p_address : "-"}
                      {/* <br />
                      <span className="answer">
                        No 21,Saparamadu Place, Waragoda, Kelaniya,Sri Lanka
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.c_address ? detail.c_address : "-"}
                      {/* <br />
                      <span className="answer">
                        No 21,Saparamadu Place, Waragoda, Kelaniya,Sri Lanka
                      </span> */}
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Email Address</p>
                    <p className="student-question">Mobile Number</p>
                    <p className="student-question">Land Number</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.email ? detail.email : "-"}
                      {/* <br />
                      <span className="answer">
                        thivankasaparamadu111@gmail.com
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.mobile ? detail.mobile : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.l_line ? detail.l_line : "-"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Next of Kin & Education Information below */}
              <div className="student-request-details-topic">
                - Next of Kin & Education Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Name</p>
                    <p className="student-question">Mobile Number</p>
                    <p className="student-question"> Land Number</p>
                    <p className="student-question">Address </p>
                    <p className="student-question"> Relationship</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.k_c_name ? detail.k_c_name : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_mobile ? detail.k_c_mobile : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_l_line ? detail.k_c_l_line : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_p_address ? detail.k_c_p_address : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_relationship ? detail.k_c_relationship : "-"}
                    </p>
                  </div>
                </div>

                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Hig Edu Qlification</p>
                    <p className="student-question">IELTS Score</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.h_e_qualification
                        ? detail.h_e_qualification
                        : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.ielts_score ? detail.ielts_score : "-"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Social Profile Links & Employment Information below */}
              <div className="student-request-details-topic">
                - Social Profile Links & Employment Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Facebook Account</p>
                    <p className="student-question">Instagram Account</p>
                    <p className="student-question"> Linkedin Account</p>
                    <p className="student-question">Other Account </p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {" "}
                      {detail.fb_link ? detail.fb_link : "-"}{" "}
                    </p>
                    <p className="student-answer">
                      {" "}
                      {detail.i_link ? detail.i_link : "-"}
                    </p>
                    <p className="student-answer">
                      {" "}
                      {detail.l_i_link ? detail.l_i_link : "-"}
                    </p>
                    <p className="student-answer">
                      {" "}
                      {detail.o_link ? detail.o_link : "-"}
                    </p>
                  </div>
                </div>

                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Employment Details</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {" "}
                      {detail.e_details ? detail.e_details : "-"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Payment and personal statement below */}
              <div className="student-request-details-topic">
                - Payment & Personal statement Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Bank Name</p>
                    <p className="student-question">Payment Amount</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {" "}
                      {detail.p_bank ? detail.p_bank : "-"}
                    </p>
                    <p className="student-answer">
                      Rs {detail.p_amount ? detail.p_amount : "-"}
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper"></div>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

export default ApprovedDetails;
