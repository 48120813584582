import React, { useState, useEffect } from "react";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import Header from "../../Components/Admin/header";
import "../../Css/Admin/addbatch.css";
import { Button } from "@mui/material";
import Port from "../../port";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Moment from "moment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Addbatch() {
  const [batch, setbatch] = useState([]);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [b_name, setb_name] = useState("");
  const [b_s_date, setb_s_date] = useState("");
  const [b_e_date, setb_e_date] = useState("");

  const [c_b_name, setc_b_name] = useState("");
  const [c_b_s_date, setc_b_s_date] = useState("");
  const [c_b_e_date, setc_b_e_date] = useState("");

  const [w_delete, setw_delete] = useState(false);
  const [w_add, setw_add] = useState(false);
  const [w_update, setw_update] = useState(false);
  const [w_hold, setw_hold] = useState(false);
  const [w_resume, setw_resume] = useState(false);

  const [deleteid, setdeleteid] = useState("");
  const [updateid, setupdateid] = useState("");

  const newdate = Moment().format("YYYY-MM-DD");
  
  const vertical = "top";
  const horizontal = "right";

  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/batch`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setbatch(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const addbtn = (vlaue) => {
    const data = { b_name, b_s_date, b_e_date };
    if (vlaue === "Add") {
      axios
        .post(`http://${Port}/admin/batch`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setw_add(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };
  const handleClicUpdate = (id) => {
    setOpen1(true);
    setupdateid(id);
    update(id);
  };

  const update = (id) => {
    axios
      .get(`http://${Port}/admin/batch/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setc_b_name(res.data.b_name);
        setc_b_s_date(res.data.b_s_date);
        setc_b_e_date(res.data.b_e_date);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatebtn = (vlaue) => {
    const data = { c_b_name, c_b_s_date, c_b_e_date };
    if (vlaue === "Update") {
      axios
        .put(`http://${Port}/admin/batch/${updateid}`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("Data Delete");
          setw_update(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };

  const handleClickdelete = (id) => {
    setOpen(true);
    setdeleteid(id);
  };

  const deletebtn = (vlaue) => {
    if (vlaue === "Delete") {
      axios
        .delete(`http://${Port}/admin/batch/${deleteid}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("Data Delete");
          setw_delete(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };

  const handleClickhold = (holdid) => {
    axios
      .get(`http://${Port}/admin/batch/hold/${holdid}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log("Data Delete");
        setw_hold(true);
        setTimeout(() => {
          window.location.reload();
        }, 700);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickresume = (resumeid) => {
    axios
      .get(`http://${Port}/admin/batch/resume/${resumeid}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log("Data Delete");
        setw_resume(true);
        setTimeout(() => {
          window.location.reload();
        }, 700);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setw_delete(false);
    setw_update(false);
    setw_add(false);
    setw_hold(false);
  };

  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          <Button
            variant="contained"
            className="courseadd-btn"
            onClick={handleClickOpen2}
            color="error"
          >
            Add the Batch
          </Button>
          <div className="card-wapper-batch">
            {batch.map((data, index) => (
              <div className="course" key={index}>
                {newdate < data.b_s_date &&
                newdate < data.b_e_date &&
                data.status === "Resume" ? (
                  <div className="preview-pending">
                    <h6 className="contend-main">Batch Status</h6>
                    <h3 className="toipc-main">Pending Batch </h3>
                  </div>
                ) : newdate >= data.b_s_date &&
                  newdate <= data.b_e_date &&
                  data.status === "Resume" ? (
                  <div className="preview-inprogress">
                    <h6 className="contend-main">Batch Status</h6>
                    <h3 className="toipc-main">In Progress Batch</h3>
                  </div>
                ) : newdate > data.b_s_date &&
                  newdate > data.b_e_date &&
                  data.status === "Resume" ? (
                  <div className="preview-end">
                    <h6 className="contend-main">Batch Status</h6>
                    <h3 className="toipc-main">End Batch</h3>
                  </div>
                ) : (
                  <div className="preview-hold">
                    <h6 className="contend-main">Batch Status</h6>
                    <h3 className="toipc-main">Hold Batch</h3>
                  </div>
                )}
                <div className="info">
                  <h6 className="contend">Batch Name</h6>
                  <h2 className="toipc">{data.b_name}</h2>
                  <div className="main-subtopic">
                    <div className="subtopic">
                      <h6 className="contend">Batch Start Date</h6>
                      <h3 className="toipc">{data.b_s_date}</h3>
                    </div>
                    <div className="subtopic">
                      <h6 className="contend">Batch End Date</h6>
                      <h3 className="toipc">{data.b_e_date}</h3>
                    </div>
                  </div>
                  <div className="btnpos">
                    <button
                      className="btn"
                      onClick={() => handleClicUpdate(data.id)}
                    >
                      Update
                    </button>
                    <button
                      className="btn2"
                      onClick={() => handleClickdelete(data.id)}
                    >
                      Delete
                    </button>
                    {data.status === "Resume" ? (
                      <button
                        className="btn3"
                        onClick={() => handleClickhold(data.id)}
                      >
                        Hold
                      </button>
                    ) : (
                      <button
                        className="btn4"
                        onClick={() => handleClickresume(data.id)}
                      >
                        Resume
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Dialog
            open={open2}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do You Want Add The Batch?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box
                  sx={{
                    "& > :not(style)": {
                      marginBottom: 1,
                      marginTop: 1,
                      minWidth: "45ch",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter The Batch Name"
                    variant="outlined"
                    value={b_name}
                    onChange={(e) => {
                      setb_name(e.target.value);
                    }}
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Select The Batch Start Date"
                    variant="outlined"
                    type="date"
                    value={b_s_date}
                    onChange={(e) => {
                      setb_s_date(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Select The Batch End Date"
                    variant="outlined"
                    type="date"
                    value={b_e_date}
                    onChange={(e) => {
                      setb_e_date(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => addbtn("Add")} autoFocus>
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do You Want Delete This Batch?"}
            </DialogTitle>
            {/* <DialogContent>
              <DialogContentText id="alert-dialog-description">
                if you want delete this user
              </DialogContentText>
            </DialogContent> */}
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => deletebtn("Delete")} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open1}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do You Want Update This Batch?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      marginBottom: 1,
                      marginTop: 1,
                      minwidth: "45ch",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter The Batch Name"
                    variant="outlined"
                    value={c_b_name}
                    onChange={(e) => {
                      setc_b_name(e.target.value);
                    }}
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Select The Batch Start Date"
                    variant="outlined"
                    type="date"
                    value={c_b_s_date}
                    onChange={(e) => {
                      setc_b_s_date(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Select The Batch End Date"
                    variant="outlined"
                    type="date"
                    value={c_b_e_date}
                    onChange={(e) => {
                      setc_b_e_date(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => updatebtn("Update")} autoFocus>
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={w_add}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Batch Added
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_delete}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Batch Deleted
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_update}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
              Batch Updated
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_hold}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Batch Hold
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_resume}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Batch Resume
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}
