import React, { useState, useEffect } from "react";
import "../../Css/Admin/request-details.css";
import MonNavBar from "./mob_nav_bar";
import AdminNavBar from "./admin_nav_bar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./header";
import Port from "../../port.js";
import axios from "axios";
import Alerts from "./Alert";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function ApprovedCoursePayment() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const type = params.type;
  const status = params.status;
  const [detail, setDetails] = useState({});
  const [courseActive, setCourseActive] = useState(false);
  const [active, setActive] = useState(false);
  const [ertype, setErtype] = useState();
  const [ermes, setErmes] = useState();
  let nic = detail.n_o_p_number;
  const [progress, setProgress] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (type === "0") {
      setCourseActive(true);
      axios
        .get(`http://${Port}/admin/recent/student/course/payment/${id}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });
    } else {
      setCourseActive(false);
      axios
        .get(
          `http://${Port}/admin/recent/student/university/payment/${id}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });
    }
  }, [id, token,type]);
 
  const paymentSlipHandler = () => {
    axios({
      url: `http://${Port}/admin/semester/payment/slip/${detail.image}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };

  const approveHandler = () => {
    const confirmBox = window.confirm(
      "Are you sure want to approve this payment?"
    );
    if (confirmBox === true) {
      if (type === "0") {
        axios
          .get(`http://${Port}/admin/course/payment/status/update/${id}`, {
            headers: {
              Authorization: `bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.status === null) {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else if (res.data.status !== 1) {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("success");
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            }
          })
          .catch((err) => {
            setActive(true);
            setProgress(false);
            console.log(err);
            setErmes("Something went wrong!");
            setTimeout(true);
            setErtype("error");
          });
      } else {
        axios
          .get(
            `http://${Port}/admin/university/payment/status/update/${id}`,
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status === null) {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else if (res.data.status !== 1) {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("success");
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            }
          })
          .catch((err) => {
            setActive(true);
            setProgress(false);
            console.log(err);
            setErmes("Something went wrong!");
            setTimeout(true);
            setErtype("error");
          });
      }
    }
  };

  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MonNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}

          <div className="body-details-wrapper clearfix">
            <ArrowBackIcon
              className="back-btn"
              onClick={() => {
                navigate(-1);
              }}
            />
            <ReceiptIcon
              className="action-icons"
              titleAccess="View payment slip"
              onClick={paymentSlipHandler}
            />

            <div className="student-request-details-header-wrapper">
              <div className="student-request-id">
                Request ID : <b>{detail.ID ? detail.ID : "-"}</b>
              </div>
              <div className="student-image"></div>
              <div className="student-request-timedate">
                Submited Date :{" "}
                <b>{detail.submited_date ? detail.submited_date : "-"}</b>
              </div>
            </div>
            <br />
            <div className="student-request-details-body-wrapper ">
              {/* basic informations below */}
              <div className="student-request-details-topic">
                - Basic Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper ">
                    <p className="student-question">Full Name </p>
                    <p className="student-question">NIC/Passport</p>

                    {courseActive && (
                      <p className="student-question">Course Name</p>
                    )}
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.name ? detail.name : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.nic ? detail.nic : "-"}
                    </p>
                    <p className="student-answer">
                      {courseActive && (
                        <div>{detail.course ? detail.course : "-"}</div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Email Address</p>
                    <p className="student-question">Mobile Number</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.email ? detail.email : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.contact ? detail.contact : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <br />
              {/* Next of Kin & Education Information below */}
              <div className="student-request-details-topic">
                - Payment Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Bank Name</p>
                    <p className="student-question">Branch Name</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.bank_name ? detail.bank_name : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.branch_name ? detail.branch_name : "-"}
                    </p>
                  </div>
                </div>

                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Diposited Date</p>
                    <p className="student-question">Diposited Amount </p>
                    <p className="student-question">Payment Type </p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.deposited_date ? detail.deposited_date : "-"}
                    </p>
                    <p className="student-answer">
                      Rs{" "}
                      {detail.deposited_amount ? detail.deposited_amount : "0"}
                      .00
                    </p>
                    <p className="student-answer">
                      {detail.type ? detail.type : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <br />
              {status === "rejected" && (
                <div>
                  <div
                    className="student-request-details-topic"
                    style={{ color: "red" }}
                  >
                    - Rejected Reason -
                  </div>
                  <div
                    className="student-request-details-body-section1"
                    style={{ color: "red", fontWeight: "600" }}
                  >
                    <div className="student-personal-details-wrapper">
                      <div className="student-question-wrapper">
                        <p className="student-question">
                          {" "}
                          {detail.reject_message
                            ? detail.reject_message
                            : "-"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="btn-wrapper">
                    <button className="approve-btn" onClick={approveHandler}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        {" "}
                        <p style={{ marginTop: "3px" }}>Approve</p>
                        <CheckCircleOutlineIcon fontSize="small" />
                      </div>
                    </button>
                  </div>
                </div>
              )}
              <br />
              <br />
              <div className="action-button-wrapper clearfix">
                <div className="alert-wrapper">
                  {active && (
                    <Alerts
                      message={ermes}
                      type={ertype}
                      setActive={setActive}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

export default ApprovedCoursePayment;
