import React, { useEffect, useState } from "react";
import "../../Css/Admin/admin_nav_bar.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { NavLink } from "react-router-dom";
import Logo from "../../Assets/cadd_logo.png";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import Badge from "@mui/material/Badge";
import axios from "axios";
import Port from "../../port";
// import SubjectIcon from "@mui/icons-material/Subject";

function AdminNavBar() {
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/pending/request/count`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`http://${Port}/admin/pending/course/payment/request/count`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setCount2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        `http://${Port}/admin/pending/university/payment/request/count`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCount3(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const position = localStorage.getItem("position");
  return (
    <div className="navbar-container">
      <div className="icon-container">
        <center>
          <img src={Logo} alt="Logo" width="77px" />
        </center>
      </div>
      <div className="links-container">
        <div className="navbar-body">
          <p className="navbar-section-header">Navigation</p>
          <NavLink to="/dashboard" className="navbar-link">
            <div className="navbar-section-detail">
              <HomeOutlinedIcon fonrsize="small" />
              <p>Dashboard</p>
            </div>
          </NavLink>
        </div>
        {position === "Administrator" || position === "Manager" ? (
          <div className="navbar-body">
            <p className="navbar-section-header">Pending Requests & Payments</p>
            <NavLink to="/student-request" className="navbar-link">
              <div className="navbar-section-detail">
                <Badge badgeContent={count} size="small" color="error">
                  <MarkUnreadChatAltOutlinedIcon fontSize="small" />
                </Badge>
                <p>Pending Requests</p>
              </div>
            </NavLink>
            <NavLink to="/recent/student/payment" className="navbar-link">
              <div className="navbar-section-detail">
                <Badge badgeContent={count2 + count3} size="small" color="error">
                  <CreditCardIcon fontSize="small" />
                </Badge>
                <p>Pending Payments</p>
              </div>
            </NavLink>
          </div>
        ) : null}
        {position === "Administrator" || position === "Manager" ? (
          <div className="navbar-body">
            <p className="navbar-section-header">Registration History</p>
            <NavLink to="/approved-request" className="navbar-link">
              <div className="navbar-section-detail">
                <CheckCircleOutlineOutlinedIcon fontSize="small" />
                <p>Approved Requests</p>
              </div>
            </NavLink>
            <NavLink to="/reject-request" className="navbar-link">
              <div className="navbar-section-detail">
                <DoDisturbAltOutlinedIcon fontSize="small" />
                <p>Rejected Requests</p>
              </div>
            </NavLink>
          </div>
        ) : null}
        {position === "Administrator" || position === "Manager" ? (
          <div className="navbar-body">
            <p className="navbar-section-header">Payments History</p>
            <NavLink to="/approved/payment" className="navbar-link">
              <div className="navbar-section-detail">
                <CreditScoreIcon fontSize="small" />
                <p>Approved Payments</p>
              </div>
            </NavLink>
            <NavLink to="/rejected/payment" className="navbar-link">
              <div className="navbar-section-detail">
                <CreditCardOffIcon fontSize="small" />
                <p>Rejected Payments</p>
              </div>
            </NavLink>
          </div>
        ) : null}
        <div className="navbar-body">
          <p className="navbar-section-header">Academic Details</p>
          <NavLink to="/current/student" className="navbar-link">
            <div className="navbar-section-detail">
              <PersonOutlineOutlinedIcon fontSize="small" />
              <p>Students</p>
            </div>
          </NavLink>
          <NavLink to="/assigngroup" className="navbar-link">
            <div className="navbar-section-detail">
              <GroupsIcon fontSize="small" />
              <p>Assign Group</p>
            </div>
          </NavLink>
        </div>
        <div className="navbar-body">
          {position === "Administrator" || position === "Manager" ? (
            <p className="navbar-section-header">Others</p>
          ) : null}
          {position === "Administrator" || position === "Manager" ? (
            <>
              <NavLink to="/course" className="navbar-link">
                <div className="navbar-section-detail">
                  <PlaylistAddIcon fontSize="small" />
                  <p>Add Course</p>
                </div>
              </NavLink>
              {/* <NavLink to="/subject" className="navbar-link">
                <div className="navbar-section-detail">
                  <SubjectIcon fonrsize="small" />
                  <p>Add Optional Subjects</p>
                </div>
              </NavLink> */}
            </>
          ) : null}
          {position === "Administrator" ? (
            <NavLink to="/addusers" className="navbar-link">
              <div className="navbar-section-detail">
                <GroupAddIcon fontSize="small" />
                <p>Add User</p>
              </div>
            </NavLink>
          ) : null}
          {position === "Administrator" || position === "Manager" ? (
            <NavLink to="/addbatch" className="navbar-link">
              <div className="navbar-section-detail">
                <Diversity3Icon fontSize="small" />
                <p>Add Batch</p>
              </div>
            </NavLink>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdminNavBar;
