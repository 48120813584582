import React, { useState, useEffect } from "react";
import "../../Css/Admin/request-details.css";
import MonNavBar from "./mob_nav_bar";
import AdminNavBar from "./admin_nav_bar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Header from "./header";
import Port from "../../port.js";
import axios from "axios";
import Alerts from "./Alert";
import RejectPaymentMessage from "./reject_payment_message";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Loader from "../../Components/Admin/Loader";

function RecentCoursePayment() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const type = params.type;
  const [progress, setProgress] = useState(false);
  const [detail, setDetails] = useState({});
  const [courseActive, setCourseActive] = useState(false);
  const [active, setActive] = useState(false);
  const [ertype, setErtype] = useState();
  const [ermes, setErmes] = useState();
  const [popupActive, setPopupActive] = useState(false);
  const [rejectMes, setRejectMes] = useState();
  const [stdDetail, setStdDetails] = useState({});
  const [loader, setloader] = useState(false);
  let nic = params.nic;

  const [totPaidAmount, setTotPaidAmount] = useState();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (type === "0") {
      setCourseActive(true);
      axios
        .get(`http://${Port}/admin/recent/student/course/payment/${id}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });
      axios
        .get(
          `http://${Port}/admin/student/balance/course/payment/${nic}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setTotPaidAmount(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });

      axios
        .get(`http://${Port}/admin/student/detail/${nic}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setStdDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });
    } else {
      setCourseActive(false);
      axios
        .get(
          `http://${Port}/admin/recent/student/university/payment/${id}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });

      axios
        .get(
          `http://${Port}/admin/student/balance/university/payment/${nic}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setTotPaidAmount(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });
      axios
        .get(`http://${Port}/admin/student/detail/${nic}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setStdDetails(res.data);
        })
        .catch((err) => {
          console.log(err);
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        });
    }
  }, [id, token, nic, type]);

  const paymentSlipHandler = () => {
    axios({
      url: `http://${Port}/admin/semester/payment/slip/${detail.image}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };

  const approveHandler = () => {
    const confirmBox = window.confirm(
      "Are you sure want to approve this payment?"
    );
    if (confirmBox === true) {
      setloader(true);
      if (type === "0") {
        axios
          .post(
            `http://${Port}/admin/course/payment/status/update/${id}/${stdDetail.studentID}`,
            detail,
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status === null) {
              setloader(false);
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else if (res.data.status !== 1) {
              setloader(false);
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else {
              setActive(true);
              setProgress(false);
              setloader(false);
              setErmes(res.data.message);
              setErtype("success");
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            }
          })
          .catch((err) => {
            setActive(true);
            setProgress(false);
            console.log(err);
            setErmes("Something went wrong!");
            setTimeout(true);
            setErtype("error");
          });
      } else {
        axios
          .post(
            `http://${Port}/admin/university/payment/status/update/${id}/${stdDetail.studentID}`,
            detail,
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status === null) {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else if (res.data.status !== 1) {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("error");
            } else {
              setActive(true);
              setProgress(false);
              setErmes(res.data.message);
              setErtype("success");
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            }
          })
          .catch((err) => {
            setActive(true);
            setProgress(false);
            console.log(err);
            setErmes("Something went wrong!");
            setTimeout(true);
            setErtype("error");
          });
      }
    }
  };
  const rejectHandler = () => {
    setPopupActive(false);
    if (type === "0") {
      axios
        .get(
          `http://${Port}/admin/course/payment/status/update/${id}/${rejectMes}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === null) {
            setActive(true);
            setProgress(false);
            setErmes(res.data.message);
            setErtype("error");
          } else if (res.data.status !== 1) {
            setActive(true);
            setProgress(false);
            setErmes(res.data.message);
            setErtype("error");
          } else {
            setActive(true);
            setProgress(false);
            setErmes(res.data.message);
            setErtype("warning");
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          }
        })
        .catch((err) => {
          setActive(true);
          setProgress(false);
          console.log(err);
          setErmes("Something went wrong!");
          setTimeout(true);
          setErtype("error");
        });
    } else {
      axios
        .get(
          `http://${Port}/admin/university/payment/status/update/${id}/${rejectMes}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === null) {
            setActive(true);
            setProgress(false);
            setErmes(res.data.message);
            setErtype("error");
          } else if (res.data.status !== 1) {
            setActive(true);
            setProgress(false);
            setErmes(res.data.message);
            setErtype("error");
          } else {
            setActive(true);
            setProgress(false);
            setErmes(res.data.message);
            setErtype("warning");
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          }
        })
        .catch((err) => {
          setActive(true);
          setProgress(false);
          console.log(err);
          setErmes("Something went wrong!");
          setTimeout(true);
          setErtype("error");
        });
    }
  };

  const popupHandler = () => {
    setPopupActive(true);
  };

  return (
    <div className="container">
      <Loader open={loader} />
      {popupActive && (
        <RejectPaymentMessage
          setRejectMes={setRejectMes}
          setPopupActive={setPopupActive}
          rejectHandler={rejectHandler}
        />
      )}
      <div className="mob-navbar-wrapper">
        <MonNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}

          <div className="body-details-wrapper clearfix">
            <ArrowBackIcon
              className="back-btn"
              onClick={() => {
                navigate(-1);
              }}
            />
            <ReceiptIcon
              className="action-icons"
              titleAccess="View payment slip"
              onClick={paymentSlipHandler}
            />
            <div className="student-request-details-header-wrapper">
              <div className="student-request-id">
                Request ID : <b>{detail.ID ? detail.ID : "-"}</b>
              </div>
              <div className="student-image"></div>
              <div className="student-request-timedate">
                Submited Date :{" "}
                <b>{detail.submited_date ? detail.submited_date : "-"}</b>
              </div>
            </div>
            <br />
            <div className="student-request-details-body-wrapper ">
              {/* basic informations below */}
              <div className="student-request-details-topic">
                - Basic Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper ">
                    <p className="student-question">Full Name </p>
                    <p className="student-question">NIC/Passport</p>

                    {courseActive && (
                      <p className="student-question">Course Name</p>
                    )}
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.name ? detail.name : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.nic ? detail.nic : "-"}
                    </p>
                    <p className="student-answer">
                      {courseActive && (
                        <div>{detail.course ? detail.course : "-"}</div>
                      )}
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Email Address</p>
                    <p className="student-question">Mobile Number</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.email ? detail.email : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.contact ? detail.contact : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <br />
              {/* Next of Kin & Education Information below */}
              <div className="student-request-details-topic">
                - Payment Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Bank Name</p>
                    <p className="student-question">Branch Name</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.bank_name ? detail.bank_name : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.branch_name ? detail.branch_name : "-"}
                    </p>
                  </div>
                </div>

                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Diposited Date</p>
                    <p className="student-question">Diposited Amount </p>
                    <p className="student-question">Payment Type </p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.deposited_date ? detail.deposited_date : "-"}
                    </p>
                    <p className="student-answer">
                      Rs{" "}
                      {detail.deposited_amount ? detail.deposited_amount : "0"}
                      .00
                    </p>
                    <p className="student-answer">
                      {detail.type ? detail.type : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="student-request-details-topic">
                - Payment History -
              </div>
              <div
                className="student-request-details-body-section1"
                style={{ color: "red" }}
              >
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">
                      {" "}
                      {!courseActive
                        ? "Total University Fee"
                        : "Total Course Fee"}
                    </p>
                    <p className="student-question">Paid Payment</p>
                    <p className="student-question">Balance Payment</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {!courseActive ? (
                        "Rs 165000.00"
                      ) : (
                        <div>Rs {stdDetail.c_fee}.00</div>
                      )}
                    </p>
                    <p className="student-answer">
                      Rs {totPaidAmount ? totPaidAmount : "-"}.00
                    </p>
                    <p className="student-answer">
                      {!courseActive ? (
                        <div>Rs {165000 - totPaidAmount}.00</div>
                      ) : (
                        <div>Rs {stdDetail.c_fee - totPaidAmount}.00</div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="action-button-wrapper clearfix">
                <div className="alert-wrapper">
                  {active && (
                    <Alerts
                      message={ermes}
                      type={ertype}
                      setActive={setActive}
                    />
                  )}
                </div>
                <div className="btn-wrapper">
                  <button className="reject-btn" onClick={popupHandler}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      <p style={{ marginTop: "3px" }}>Reject</p>
                      <DoDisturbAltIcon fontSize="small" />
                    </div>
                  </button>

                  <button className="approve-btn" onClick={approveHandler}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      <p style={{ marginTop: "3px" }}>Approve</p>
                      <CheckCircleOutlineIcon fontSize="small" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

export default RecentCoursePayment;
