import React, { useState, useEffect } from "react";
import "../../Css/Admin/Assinggroup.css";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
// import { useNavigate } from "react-router-dom";
import Header from "../../Components/Admin/header";
import axios from "axios";
import Port from "../../port";
import Icon from "../../Assets/request-message.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import Loader from "../../Components/Admin/Loader";
import MuiAlert from "@mui/material/Alert";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Assinggroup() {
  const [found, setFound] = useState("");
  const [details, setDetails] = useState([]);
  const token = localStorage.getItem("token");

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const [b_name, setb_name] = useState("");
  const [checkbox, setcheckbox] = useState([]);

  const [loader, setloader] = React.useState(false);

  const [b_null, setb_null] = useState(false);
  const [check_null, setcheck_null] = useState(false);
  const [student, setstudent] = useState(false);

  const newdate = Moment().format("YYYY-MM-DD");

  const vertical = "top";
  const horizontal = "right";

  const time = new Date().getHours();
  let greeting;
  if (time < 12) {
    greeting = "Good Morning!";
  } else if (12 < time < 16) {
    greeting = "Good Afternoon!";
  } else if (16 < time < 18) {
    greeting = "Good Evening!";
  } else {
    greeting = "Good Night!";
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        fetch(`http://${Port}/admin/batch/date/${newdate}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setOptions(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/student/assing`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const checkhandler = (e) => {
    const { value, checked } = e.target;
    setcheckbox([...checkbox, value]);
    if (!checked) {
      setstudent(checkbox.filter((student) => student !== value));
    }
  };

  const assignHandler = () => {
    if (b_name === "") {
      setb_null(true);
    } else if (checkbox.length === 0) {
      setcheck_null(true);
    } else {
      setloader(true);

      const assignedData = [];
      for (let index = 0; index < checkbox.length; index++) {
        let data = {
          n_o_p_number: checkbox[index],
          b_name: b_name,
        };
        assignedData.push(data);
      }
      for (var i = 0; i < assignedData.length; i++) {
        axios
          .put(
            `http://${Port}/admin//batch/assing/group`,
            assignedData[i],
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setloader(false);
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleClose = () => {
    setb_null(false);
    setcheck_null(false);
  };
  const requests = details.filter((data) => {
    return (
      data.n_o_p_number.toLowerCase().includes(found.toLowerCase()) ||
      data.mobile.toLowerCase().includes(found.toLowerCase()) ||
      data.name.toLowerCase().includes(found.toLowerCase())
    );
  });

  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          <Loader open={loader} />
          {/* ------------------------------------------------------ */}
          <div className="student-request-status-wrapper clearfix">
            <div>
              <input
                type="search"
                placeholder="Search Name or NIC..."
                className="student-request-search"
                onChange={(event) => {
                  setFound(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="assing-group-batch">
            <div className="assing-group-batch-select">
              <Autocomplete
                id="batch"
                className="size"
                sx={{ width: 300 }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                getOptionLabel={(option) => option.b_name}
                onChange={(e, value) => {
                  setb_name(value.b_name);
                }}
                options={options}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select The Bacth"
                    value={b_name}
                    variant="outlined"
                    name="b_name"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="assing-group-batch-select-button">
              <button
                className="assing-group-batch-button"
                onClick={assignHandler}
              >
                Assing
              </button>
            </div>
          </div>

          <div className="student-request-table-wrapper">
            <div className="student-request-table-header">
              <div id="assing-group-body-col1">
                <center> CHECK</center>
              </div>
              <div id="assing-group-body-col6">
                <center> COURSE</center>
              </div>
              <div id="assing-group-body-col2">
                <center>PASS or NIC</center>
              </div>
              <div id="assing-group-body-col3">
                <center> NAME</center>
              </div>
              <div id="assing-group-body-col4">
                <center> CONTACT</center>
              </div>
            </div>
            {details.length !== 0 ? (
              <div>
                {requests.map((detail, index) => (
                  <div className="student-request-table-body" key={index}>
                    <div className="assing-group-body-col1">
                      <center>
                        <input
                          type="checkbox"
                          value={detail.n_o_p_number}
                          onChange={checkhandler}
                        />
                      </center>
                    </div>
                    <div className="assing-group-body-col6">
                      <center> {detail.i_course}</center>
                    </div>
                    <div className="assing-group-body-col2">
                      <center> {detail.n_o_p_number}</center>
                    </div>
                    <div className="assing-group-body-col3">
                      <center> {detail.f_name}</center>
                    </div>
                    <div className="assing-group-body-col4">
                      <center> {detail.mobile}</center>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-message-container">
                <div className="empty-message-detail-wrapper">
                  <center>
                    <img src={Icon} width="50px" alt="request icon" />
                    <h4 className="empty-message-header">{greeting}</h4>
                    <p className="empty-message">
                      There're no any student to assing!
                    </p>
                  </center>
                </div>
              </div>
            )}
          </div>
          {/* ------------------------------------------------------ */}
          <Snackbar
            open={b_null}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Please select a batch to assign students
            </Alert>
          </Snackbar>
          <Snackbar
            open={check_null}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Please select a studnet/students to assign batch
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

export default Assinggroup;
