import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Requestform from "./Pages/Admin/request_form";
import AdminHome from "./Pages/Admin/admin_home";
import Adminlogin from "./Pages/Admin/admin_login";
import StudentsRequests from "./Pages/Admin/students_requests";
import RequestDetails from "./Components/Admin/request_details";
import Coursepage from "./Pages/Admin/course_page";
import ApprovedDetails from "./Components/Admin/approved_details";
import ApprovedRequests from "./Pages/Admin/approved_requests";
import RejectRequests from "./Pages/Admin/rejected_requests";
import RejectDetails from "./Components/Admin/reject_details";
import PendingStudentsPayment from "./Pages/Admin/pending_students_payment";
import RecentCoursePayment from "./Components/Admin/recent_course_university_payments";
import Addusers from "./Pages/Admin/addusers";
import Addbatch from "./Pages/Admin/addbatch";
import ApprovedPayment from "./Pages/Admin/approved_payment";
import ApprovedCoursePayment from "./Components/Admin/approved_course_university_payments";
import RejectedPayment from "./Pages/Admin/reject_payments";
import Student from "./Pages/Admin/students";
import StudentsDetail from "./Components/Admin/students_detail";
import Assigngroup from "./Pages/Admin/assigngroup";
import PaymentHistory from "./Pages/Admin/payment_history";
import Addsubject from "./Pages/Admin/addsubject";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Adminlogin />} />

          <Route path="/requestform" element={<Requestform />} />
          <Route path="/dashboard" element={<AdminHome />} />
          <Route path="/student-request" element={<StudentsRequests />} />
          <Route
            path="/student-request/details/id-:id"
            element={<RequestDetails />}
          />
          <Route path="/course" element={<Coursepage />} />
          <Route path="/subject/:name" element={<Addsubject />} />
          <Route
            path="/approved-request/details/id-:id"
            element={<ApprovedDetails />}
          />
          <Route
            path="/reject-request/details/id-:id"
            element={<RejectDetails />}
          />
          <Route path="/approved-request" element={<ApprovedRequests />} />
          <Route path="/reject-request" element={<RejectRequests />} />
          <Route
            path="/recent/student/payment"
            element={<PendingStudentsPayment />}
          />
          <Route
            path="/recent/course/payment/:type/:id"
            element={<RecentCoursePayment />}
          />
          <Route path="/addusers" element={<Addusers />} />
          <Route path="/addbatch" element={<Addbatch />} />
          <Route
            path="/recent/course/payment/:type/:id/:nic"
            element={<RecentCoursePayment />}
          />
          <Route
            path="/approved/payment/:type/:id/:status"
            element={<ApprovedCoursePayment />}
          />
          <Route path="/approved/payment" element={<ApprovedPayment />} />
          <Route path="/rejected/payment" element={<RejectedPayment />} />

          <Route path="/current/student" element={<Student />} />

          <Route path="/assigngroup" element={<Assigngroup />} />
          <Route path="/student/:nic" element={<StudentsDetail />} />
          <Route path="/payment/history/:nic" element={<PaymentHistory />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
