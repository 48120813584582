import React from "react";
import "../../Css/Admin/header.css"
// import axios from "axios";
// import Port from "../../port";
import AccountMenu from "../../Components/Admin/profile";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import Badge from "@mui/material/Badge";
// import { useNavigate } from 'react-router-dom'
 

function Header() {
      // const navigate=useNavigate()
  // const [count, setCount] = useState(1);
  // const token=localStorage.getItem("token")

  //     useEffect(() => {
  //       axios
  //         .get(`http://${Port}:8090/admin/pending/request/count`, {
  //           headers: {
  //             Authorization: `bearer ${token}`,
  //           },
  //         })
  //         .then((res) => {
  //           setCount(res.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }, [token]);
  
  return (
    <div className="body-header">
      
      {/* <Badge color="error" badgeContent={0} style={{ marginTop: "13px" }}>
        <NotificationsIcon onClick={() => { navigate("/student-request"); }} style={{cursor:"pointer"}} />
      </Badge> */}
      <AccountMenu />
    </div>
  );
}

export default Header