import React, { useState, useEffect } from "react";
import "../../Css/Admin/pending_students_payment.css";
import "../../Css/Admin/StudentRequest.css";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Admin/header";
import axios from "axios";
import Port from "../../port";
import Icon from "../../Assets/request-message.svg";
function ApprovedPayment() {
  const [found, setFound] = useState("");
  const [courseDetails, setCourseDetails] = useState([]);
  const [uniDetails, setUniDetails] = useState([]);
  const token = localStorage.getItem("token");
  const [category, setCategory] = useState(false);

  const time = new Date().getHours();
  let greeting;
  if (time < 12) {
    greeting = "Good Morning!";
  } else if (12 < time < 16) {
    greeting = "Good Afternoon!";
  } else if (16 < time < 18) {
    greeting = "Good Evening!";
  } else {
    greeting = "Good Night!";
  }

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/approve/student/course/payments`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setCourseDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`http://${Port}/admin/approve/student/university/payments`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setUniDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const coursePayments = courseDetails.filter((data) => {
    return (
      data.nic.toLowerCase().includes(found.toLowerCase()) ||
      data.contact.toLowerCase().includes(found.toLowerCase()) ||
      data.email.toLowerCase().includes(found.toLowerCase())
    );
  });
  const uniPayments = uniDetails.filter((data) => {
    return (
      data.nic.toLowerCase().includes(found.toLowerCase()) ||
      data.contact.toLowerCase().includes(found.toLowerCase()) ||
      data.email.toLowerCase().includes(found.toLowerCase())
    );
  });
  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}

          <div className="student-request-category-wrapper">
            <div
              className={
                category
                  ? "student-request-category-one"
                  : "student-request-category-active"
              }
              onClick={() => {
                setCategory(false);
              }}
            >
              Approved Course Payments
            </div>
            <div
              className={
                !category
                  ? "student-request-category-one"
                  : "student-request-category-active"
              }
              onClick={() => {
                setCategory(true);
              }}
            >
              Approved University Payments
            </div>
          </div>
          {!category ? (
            <div>
              <div className="student-request-status-wrapper clearfix">
                <div>
                  <input
                    type="search"
                    placeholder="Search Name or NIC..."
                    className="student-request-search"
                    onChange={(event) => {
                      setFound(event.target.value);
                    }}
                  />
                </div>
                <div className="student-request-status">
                  <div className="student">
                    <FiberManualRecordIcon
                      fontSize="small"
                      style={{ color: "green" }}
                    />
                  </div>
                  <p
                    style={{
                      marginLeft: "5px",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Approved
                  </p>
                </div>
              </div>
              <div className="student-request-table-wrapper">
                <div className="student-payment-request-table-header">
                  <div id="student-payment-request-body-col1">
                    <center> STATUS</center>
                  </div>
                  <div id="student-payment-request-body-col6">
                    <center> COURSE</center>
                  </div>
                  <div id="student-payment-request-body-col2">
                    <center>PASS or NIC</center>
                  </div>
                  <div id="student-payment-request-body-col3">
                    <center> AMOUNT</center>
                  </div>
                  <div id="student-payment-request-body-col4">
                    <center> SUBMITED DATE</center>
                  </div>
                  <div id="student-payment-request-body-col5">
                    <center> ACTION</center>
                  </div>
                </div>
                {courseDetails.length !== 0 ? (
                  <div>
                    {coursePayments.map((detail, index) => (
                      <StudentsPaymentTable
                        key={index}
                        ID={detail.ID}
                        Course={detail.course}
                        NIC={detail.nic}
                        Amount={detail.deposited_amount}
                        date={detail.submited_date}
                        type={0}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empty-message-container">
                    <div className="empty-message-detail-wrapper">
                      <center>
                        <img src={Icon} width="50px" alt="request icon" />
                        <h4 className="empty-message-header">{greeting}</h4>
                        <p className="empty-message">
                          There're no any approved payments to show!
                        </p>
                      </center>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="student-request-status-wrapper clearfix">
                <div>
                  <input
                    type="search"
                    placeholder="Search Name or NIC..."
                    className="student-request-search"
                    onChange={(event) => {
                      setFound(event.target.value);
                    }}
                  />
                </div>
                <div className="student-request-status">
                  <div className="student">
                    <FiberManualRecordIcon
                      fontSize="small"
                      style={{ color: "green" }}
                    />
                  </div>
                  <p
                    style={{
                      marginLeft: "5px",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Approved
                  </p>
                </div>
              </div>
              <div className="student-request-table-wrapper">
                <div className="student-payment-request-table-header">
                  <div id="student-payment-request-body-col1">
                    <center> STATUS</center>
                  </div>
                  <div id="student-payment-request-body-col6">
                    <center> NAME</center>
                  </div>
                  <div id="student-payment-request-body-col2">
                    <center>PASS or NIC</center>
                  </div>
                  <div id="student-payment-request-body-col3">
                    <center> AMOUNT</center>
                  </div>
                  <div id="student-payment-request-body-col4">
                    <center> SUBMITED DATE</center>
                  </div>
                  <div id="student-payment-request-body-col5">
                    <center> ACTION</center>
                  </div>
                </div>
                {uniDetails.length !== 0 ? (
                  <div>
                    {uniPayments.map((detail, index) => (
                      <StudentsPaymentTable
                        key={index}
                        ID={detail.ID}
                        Course={detail.name}
                        NIC={detail.nic}
                        Amount={detail.deposited_amount}
                        date={detail.submited_date}
                        type={1}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empty-message-container">
                    <div className="empty-message-detail-wrapper">
                      <center>
                        <img src={Icon} width="50px" alt="request icon" />
                        <h4 className="empty-message-header">{greeting}</h4>
                        <p className="empty-message">
                          There're no any approved payments to show!
                        </p>
                      </center>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

export default ApprovedPayment;

function StudentsPaymentTable(props) {
  const navigate = useNavigate();

  const detailsHandler = () => {
    navigate(`/approved/payment/${props.type}/${props.ID}/approved`);
  };
  const viewHandler = () => {
    navigate(`/approved/payment/${props.type}/${props.ID}/approved`);
  };

  return (
    <div
      className="student-payment-request-table-body"
      onClick={detailsHandler}
    >
      <div className="student-payment-request-body-col1">
        <center>
          {" "}
          <FiberManualRecordIcon fontSize="small" style={{ color: "green" }} />
        </center>
      </div>
      <div className="student-payment-request-body-col6">
        <center>{props.Course ? props.Course : "-"}</center>
      </div>
      <div className="student-payment-request-body-col2">
        <center>{props.NIC ? props.NIC : "-"}</center>
      </div>
      <div className="student-payment-request-body-col3">
        <center>Rs {props.Amount ? props.Amount : "-"}.00</center>
      </div>
      <div className="student-payment-request-body-col4">
        <center>{props.date ? props.date : "-"}</center>
      </div>
      <div className="student-payment-request-body-col5">
        <center>
          {" "}
          <RemoveRedEyeOutlinedIcon
            fontSize="small"
            style={{ color: "green" }}
            onClick={viewHandler}
            className="view-request-btn"
            titleAccess="More Details.."
          />
        </center>
      </div>
    </div>
  );
}
