import React, { useEffect, useState } from "react";
import "../../Css/Admin/admin_home.css";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import Header from "../../Components/Admin/header";
import axios from "axios";
import Port from "../../port";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function AdminHome() {
  const [student, setstudent] = useState([]);

  const [Barchart, setBarchart] = useState([]);
  const [rejected, setrejected] = useState(0);
  const [pending, setpending] = useState(0);
  const [approved, setapproved] = useState(0);
  const token=localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/requestchart`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setBarchart(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/student`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setstudent(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/rejected`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setrejected(res.data.rejectedcount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/pending`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setpending(res.data.pendingcount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/approved`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setapproved(res.data.approvedcount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);
  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}
          <p className="body-topic">Dashboard</p>
          <div className="card-wrapper ">
            <div className="card1-wrapper">
              <p className="card-topic">Total Pending Requests</p>
              <p className="card-detail">{pending}</p>
              <div className="card-detail-box1">
                <h4 className="pendingadmin">Pending</h4>
                {/* <MoreHorizOutlinedIcon /> */}
              </div>
            </div>
            <div className="card2-wrapper">
              <p className="card-topic">Total Approved Requests</p>
              <p className="card-detail">{approved}</p>
              <div className="card-detail-box2 ">
                <h4 className="Issuedadmin">Issued</h4>
                {/* <DoneOutlinedIcon /> */}
              </div>
            </div>
            <div className="card3-wrapper">
              <p className="card-topic">Total Rejected Requests</p>
              <p className="card-detail">{rejected}</p>
              <div className="card-detail-box3">
                <h4 className="Rejectedadmin">Rejected</h4>
                {/* <ClearOutlinedIcon /> */}
              </div>
            </div>
          </div>

          <div className="card-wrapper ">
            <div className="card1-wrapper">
              <p className="card-topic">Total Pending Semester Payments</p>
              <p className="card-detail">{pending}</p>
              <div className="card-detail-box1">
                <h4 className="pendingadmin">Pending</h4>
                {/* <MoreHorizOutlinedIcon /> */}
              </div>
            </div>
            <div className="card2-wrapper">
              <p className="card-topic">Total Approved Semester Payments</p>
              <p className="card-detail">{approved}</p>
              <div className="card-detail-box2 ">
                <h4 className="Issuedadmin">Issued</h4>
                {/* <DoneOutlinedIcon /> */}
              </div>
            </div>
            <div className="card3-wrapper">
              <p className="card-topic">Total Rejected Semester Payments</p>
              <p className="card-detail">{rejected}</p>
              <div className="card-detail-box3">
                <h4 className="Rejectedadmin">Rejected</h4>
                {/* <ClearOutlinedIcon /> */}
              </div>
            </div>
          </div>

          <p className="body-topic">Overview</p>
          <div className="admin-dashboard-graph-wrapper">
            <div className="garph-wrapper1">
              <table className="admin-home-table">
                <thead className="admin-home-thead">
                  <tr>
                    <th className="admin-home-th">ID</th>
                    <th className="admin-home-th">Name</th>
                    <th className="admin-home-th">Date</th>
                    <th className="admin-home-th">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {student.map((data, index) => (
                    <Tabalerow
                      key={index}
                      nic={data.n_o_p_number}
                      Name={data.name}
                      Date={data.s_date}
                      Status={data.status}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="garph-wrapper2">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={Barchart}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="Rejected Requset"
                    stackId="1"
                    stroke="#ff5858"
                    fill="#ff5858"
                  />
                  <Area
                    type="monotone"
                    dataKey="Approved Requset"
                    stackId="1"
                    stroke="#82ca9d"
                    fill="#82ca9d"
                  />
                  <Area
                    type="monotone"
                    dataKey="Pending Requset"
                    stackId="1"
                    stroke="#ffc658"
                    fill="#ffc658"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            {/* <div className="garph-wrapper3"></div> */}
          </div>
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

function Tabalerow(props) {
  const [pending, setpending] = useState(false);
  const [approved, setapproved] = useState(false);
  const [rejected, setrejected] = useState(false);
  const [data] = useState(props.Status);

  useEffect(() => {
    if (data === "Pending") {
      setpending(true);
    } else if (data === "Approved") {
      setapproved(true);
    } else if (data === "Rejected") {
      setrejected(true);
    }
  }, [data]);

  return (
    <>
      <tr className="admin-home-tr">
        <td className="admin-home-td">{props.nic}</td>
        <td className="admin-home-td">{props.Name}</td>
        <td className="admin-home-td">{props.Date}</td>

        <td className="admin-home-td">
          <p
            className={
              pending
                ? "status status-pending"
                : approved
                ? "status status-approved"
                : rejected
                ? "status status-rejected"
                : null
            }
          >
            {props.Status}
          </p>
        </td>
      </tr>
    </>
  );
}

export default AdminHome;
