import React, { useEffect, useState } from "react";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import Header from "../../Components/Admin/header";
import "../../Css/Admin/subject.css";
import { useParams } from "react-router-dom";
import cardpic from "../../Assets/course_card.png";
import { Button } from "@mui/material";
import Port from "../../port";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

export default function Addsubject() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open1, setOpen1] = useState(false);

  const param = useParams();
  const subjectname = param.name;

  const [subject, setsubject] = useState([]);
  const [name, setname] = useState(subjectname);
  const [sub_name, setsub_name] = useState("");
  const [c_name, setc_name] = useState("");
  const [c_sub_name, setc_sub_name] = useState("");

  const [deleteid, setdeleteid] = useState("");
  const [updateid, setupdateid] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/subject/${subjectname}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setsubject(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, subjectname]);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClickOpen = (id) => {
    setOpen(true);
    setdeleteid(id);
  };
  const handleClickOpen1 = (id) => {
    setOpen1(true);
    setupdateid(id);
    update(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    // setw_delete(false);
    // setw_update(false);
    // setw_add(false);
  };
  const addbtn = (vlaue) => {
    const data = { name, sub_name };
    if (vlaue === "Add") {
      axios
        .post(`http://${Port}/admin/subject`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // setw_add(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };
  const deletebtn = (vlaue) => {
    if (vlaue === "Delete") {
      axios
        .delete(`http://${Port}/admin/subject/${deleteid}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("Data Delete");
          // setw_delete(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };
  const update = (id) => {
    axios
      .get(`http://${Port}/admin/subjects/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setc_name(res.data.course_name);
        setc_sub_name(res.data.sub_name);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatebtn = (vlaue) => {
    const data = { c_name, c_sub_name };
    if (vlaue === "Update") {
      axios
        .put(`http://${Port}/admin/subject/${updateid}`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // setw_update(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };
  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          <Button
            variant="contained"
            className="courseadd-btn"
            onClick={handleClickOpen2}
            color="error"
          >
            Add the Subject
          </Button>
          <div className="card-wapper">
            {subject.map((data, index) => (
              <div className="box1 red" key={index}>
                <h3 className="course-name">Main Course</h3>
                <h3 className="course-subname">{data.course_name}</h3>
                <div className="text-box-card1">
                  <h3 className="course-name">Optional Subject Name</h3>
                  <h4 className="course-subname">{data.sub_name}</h4>
                </div>
                <img className="course-img1" src={cardpic} alt="" />
                <div className="card-button">
                  <div className="update-card-button">
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => handleClickOpen1(data.id)}
                    >
                      Update
                    </Button>
                  </div>
                  <div className="delete-card-button">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleClickOpen(data.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <Dialog
              open={open2}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Do You Want Add The Subject?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Box
                    sx={{
                      "& > :not(style)": {
                        marginBottom: 1,
                        marginTop: 1,
                        minWidth: "45ch",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Course Name"
                      variant="outlined"
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                    <TextField
                      id="outlined-basic"
                      label="Enter Subject Name"
                      variant="outlined"
                      // type="number"
                      value={sub_name}
                      onChange={(e) => {
                        setsub_name(e.target.value);
                      }}
                      fullWidth
                    />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => addbtn("Add")} autoFocus>
                  Add
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Do You Want Delete This Subject?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  if you delete this subject not showed the student registration
                  form.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => deletebtn("Delete")} autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={open1}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Do You Want Update The Subject Name?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        marginBottom: 1,
                        marginTop: 1,
                        minwidth: "45ch",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Course Name"
                      variant="outlined"
                      value={c_name}
                      onChange={(e) => {
                        setc_name(e.target.value);
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                    <TextField
                      id="outlined-basic"
                      label="Enter Subject Name"
                      variant="outlined"
                      // type="number"
                      value={c_sub_name}
                      onChange={(e) => {
                        setc_sub_name(e.target.value);
                      }}
                      fullWidth
                    />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => updatebtn("Update")} autoFocus>
                  Update
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}
