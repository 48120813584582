import React, { useState } from "react";
import "../../Css/Admin/admin_login.css";
import logo from "../../Assets/cadd_logo.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Port from "../../port";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Adminlogin() {
  const [loading, setLoading] = useState(false);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [error, seterror] = useState(false);
  const [loginerror, setloginerror] = useState(false);
  const [backendnetwork, setbackendnetwork] = useState(false);
  const nav = useNavigate();

  const submithandle = () => {
    if (username === "" || password === "") {
      seterror(true);
    } else {
      setLoading(true);
      axios
        .get(`http://${Port}/user/admin/login/${username}/${password}`)
        .then((res) => {
          if (res.data.position === "Administrator") {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("position", res.data.position);
            setTimeout(() => {
              nav("/dashboard");
            }, 3000);
          } else if (res.data.position === "Manager") {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("position", res.data.position);
            setTimeout(() => {
              nav("/dashboard");
            }, 3000);
          } else if (res.data.position === "Coordinator") {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("position", res.data.position);
            setTimeout(() => {
              nav("/dashboard");
            }, 3000);
          } else {
            setTimeout(() => {
              setLoading(false);
              setloginerror(true);
              console.log(res.data);
            }, 3000);
          }
        })
        .catch((err) => {
          setbackendnetwork(true);
          setLoading(false);
        });
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    seterror(false);
    setloginerror(false);
    setbackendnetwork(false);
  };
  return (
    <div className="admin_login_body">
      <div className="admin_login_container">
        <div className="admin_login_logo">
          <img src={logo} alt="" width="200" />
        </div>
        <div className="admin_login_content">
          <div className="admin_login_heading">
            <img src={logo} alt="" width="140px" />
            <h2 className="admin_login_topic">
              Wellcome CCL Academy Admin Login
            </h2>
          </div>
          <div className="admin_login_form">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "33ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="username"
                label="Username"
                variant="outlined"
                value={username}
                onChange={(e) => {
                  setusername(e.target.value);
                }}
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
              />
              <div className="admin_login_loginbutton">
                <LoadingButton
                  size="small"
                  onClick={submithandle}
                  loading={loading}
                  color="error"
                  variant="contained"
                >
                  Log In
                </LoadingButton>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <Snackbar open={error} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Enter your credentials to continue
        </Alert>
      </Snackbar>
      <Snackbar open={loginerror} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Your username or password is incorrect
        </Alert>
      </Snackbar>
      <Snackbar
        open={backendnetwork}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
          Backend Network Not Connected
        </Alert>
      </Snackbar>
    </div>
  );
}
