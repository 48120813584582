import React, { useState, useEffect } from "react";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import Header from "../../Components/Admin/header";
import "../../Css/Admin/course_page.css";
import cardpic from "../../Assets/course_card.png";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Port from "../../port";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Coursepage() {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [course, setcourse] = useState([]);
  const [w_delete, setw_delete] = useState(false);
  const [w_update, setw_update] = useState(false);
  const [w_add, setw_add] = useState(false);

  const [c_name, setc_name] = useState("");
  const [c_fee, setc_fee] = useState("");
  const [c_duration, setc_duration] = useState("");
  const [c_subjectcount, setc_subjectcount] = useState("");
  const [e_fee, sete_fee] = useState("");
  const [u_fee, setu_fee] = useState("");
  const [s_date, sets_date] = useState("");
  const [e_date, sete_date] = useState("");

  const [name, setname] = useState("");
  const [fee, setfee] = useState("");
  const [duration, setduration] = useState("");
  const [subjectcount, setsubjectcount] = useState("");
  const [enroll_fee, setenroll_fee] = useState("");
  const [uni_fee, setuni_fee] = useState("");
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");

  const [deleteid, setdeleteid] = useState("");
  const [updateid, setupdateid] = useState("");
  const token = localStorage.getItem("token");
  const nav = useNavigate();

  const vertical = "top";
  const horizontal = "right";

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/course`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setcourse(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleSubject = (name) => {
    nav(`/subject/${name}`);
  };
  const handleClickOpen = (id) => {
    setOpen(true);
    setdeleteid(id);
  };
  const handleClickOpen1 = (id) => {
    setOpen1(true);
    setupdateid(id);
    update(id);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setw_delete(false);
    setw_update(false);
    setw_add(false);
  };

  const update = (id) => {
    axios
      .get(`http://${Port}/admin/course/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setc_name(res.data.c_name);
        setc_fee(res.data.c_fee);
        setc_duration(res.data.c_duration);
        setc_subjectcount(res.data.subject);
        sete_fee(res.data.e_fee);
        setu_fee(res.data.u_fee);
        sets_date(res.data.s_date);
        sete_date(res.data.e_date);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatebtn = (vlaue) => {
    const data = {
      c_name,
      c_fee,
      c_duration,
      c_subjectcount,
      e_fee,
      u_fee,
      s_date,
      e_date,
    };
    if (vlaue === "Update") {
      axios
        .put(`http://${Port}/admin/course/${updateid}`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("Data Delete");
          setw_update(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };
  const deletebtn = (vlaue) => {
    if (vlaue === "Delete") {
      axios
        .delete(`http://${Port}/admin/course/${deleteid}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("Data Delete");
          setw_delete(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };
  const addbtn = (vlaue) => {
    const data = {
      name,
      fee,
      duration,
      subjectcount,
      enroll_fee,
      uni_fee,
      start_date,
      end_date,
    };
    if (vlaue === "Add") {
      axios
        .post(`http://${Port}/admin/course`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setw_add(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };
  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* Card */}
          <Button
            variant="contained"
            className="courseadd-btn"
            onClick={handleClickOpen2}
            color="error"
          >
            Add the Course
          </Button>
          <div className="card-wapper">
            {course.map((data, index) => (
              <div className="box red" key={index}>
                <h2 className="course-name">{data.c_name}</h2>
                <div className="text-box-card">
                  <h3 className="course-name">Rs.{data.c_fee}</h3>
                  <h3 className="course-name">{data.c_duration} Months</h3>
                  <h4 className="course-name">
                    {data.subject} Optional Subjects
                  </h4>
                  <h4 className="course-name">Enroll Fee Rs.{data.e_fee}</h4>
                  <h4 className="course-name">
                    University Fee Rs.{data.u_fee}{" "}
                  </h4>
                </div>
                <img className="course-img" src={cardpic} alt="" />
                <div className="card-button">
                  <div className="update-card-button">
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => handleClickOpen1(data.id)}
                    >
                      Update
                    </Button>
                  </div>
                  <div className="delete-card-button">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleClickOpen(data.id)}
                    >
                      Delete
                    </Button>
                  </div>
                  <div className="view-card-button">
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() => handleSubject(data.c_name)}
                    >
                      Subjects
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Typography component={"span"} variant={"body2"}>
            <p>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Do You Want Delete This Course?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    if you delete this course not showed the student
                    registration form.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => deletebtn("Delete")} autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={open1}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Do You Want Update The Course Name?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          marginBottom: 1,
                          marginTop: 1,
                          minwidth: "45ch",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Enter The Course Name"
                        variant="outlined"
                        value={c_name}
                        onChange={(e) => {
                          setc_name(e.target.value);
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The Course Fee"
                        variant="outlined"
                        type="number"
                        value={c_fee}
                        onChange={(e) => {
                          setc_fee(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rs.
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The Course Duration"
                        variant="outlined"
                        type="number"
                        value={c_duration}
                        onChange={(e) => {
                          setc_duration(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              months
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Subject Optional Count"
                        variant="outlined"
                        type="number"
                        value={c_subjectcount}
                        onChange={(e) => {
                          setc_subjectcount(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Optional Subjects
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The Enroll Fee"
                        variant="outlined"
                        type="number"
                        value={e_fee}
                        onChange={(e) => {
                          sete_fee(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rs.
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The University Fee"
                        variant="outlined"
                        type="number"
                        value={u_fee}
                        onChange={(e) => {
                          setu_fee(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rs.
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter Batch Start Year"
                        variant="outlined"
                        type="month"
                        value={s_date}
                        onChange={(e) => {
                          sets_date(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Year
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter Batch End Year"
                        variant="outlined"
                        type="month"
                        value={e_date}
                        onChange={(e) => {
                          sete_date(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Year
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => updatebtn("Update")} autoFocus>
                    Update
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={open2}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Do You Want Add The Course Name?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box
                      sx={{
                        "& > :not(style)": {
                          marginBottom: 1,
                          marginTop: 1,
                          minWidth: "45ch",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Enter The Course Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => {
                          setname(e.target.value);
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The Course Fee"
                        variant="outlined"
                        type="number"
                        value={fee}
                        onChange={(e) => {
                          setfee(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rs.
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The Course Duration"
                        variant="outlined"
                        type="number"
                        value={duration}
                        onChange={(e) => {
                          setduration(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              months
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Optional Subject Count"
                        variant="outlined"
                        type="number"
                        value={subjectcount}
                        onChange={(e) => {
                          setsubjectcount(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Optional Subjects
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The Enroll Fee"
                        variant="outlined"
                        type="number"
                        value={enroll_fee}
                        onChange={(e) => {
                          setenroll_fee(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rs.
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter The University Fee"
                        variant="outlined"
                        type="number"
                        value={uni_fee}
                        onChange={(e) => {
                          setuni_fee(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rs.
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter Batch Start Year"
                        variant="outlined"
                        type="month"
                        value={start_date}
                        onChange={(e) => {
                          setstart_date(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Year
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                      <TextField
                        id="outlined-basic"
                        label="Enter Batch End Year"
                        variant="outlined"
                        type="month"
                        value={end_date}
                        onChange={(e) => {
                          setend_date(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Year
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => addbtn("Add")} autoFocus>
                    Add
                  </Button>
                </DialogActions>
              </Dialog>
            </p>
          </Typography>

          <Snackbar
            open={w_delete}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Course Deleted
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_update}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
              Course Name Updated
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_add}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Course Name Added
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}
