import React, { useState, useEffect } from "react";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import Header from "../../Components/Admin/header";
import { Button } from "@mui/material";
import "../../Css/Admin/addusers.css";
import admin from "../../Assets/admin.png";
import manager from "../../Assets/manager.png";
import supervisor from "../../Assets/supervisor.png";
import Port from "../../port";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Addusers() {
  const [user, setuser] = useState([]);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [position, setposition] = useState("");

  const [c_username, setc_username] = useState("");
  const [c_password, setc_password] = useState("");
  const [c_position, setc_position] = useState("");

  const [deleteid, setdeleteid] = useState("");
  const [updateid, setupdateid] = useState("");

  const [w_delete, setw_delete] = useState(false);
  const [w_add, setw_add] = useState(false);
  const [w_update, setw_update] = useState(false);

  const vertical = "top";
  const horizontal = "right";

  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/user`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setuser(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClickdelete = (id) => {
    setOpen(true);
    setdeleteid(id);
  };

  const deletebtn = (vlaue) => {
    if (vlaue === "Delete") {
      axios
        .delete(`http://${Port}/admin/user/${deleteid}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("Data Delete");
          setw_delete(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };

  const addbtn = (vlaue) => {
    const data = { username, password, position };
    if (vlaue === "Add") {
      axios
        .post(`http://${Port}/admin/user`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setw_add(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };

  const handleClicUpdate = (id) => {
    setOpen1(true);
    setupdateid(id);
    update(id);
  };

  const update = (id) => {
    axios
      .get(`http://${Port}/admin/user/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setc_username(res.data.username);
        setc_password(res.data.password);
        setc_position(res.data.position);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatebtn = (vlaue) => {
    const data = { c_username, c_password, c_position };
    if (vlaue === "Update") {
      axios
        .put(`http://${Port}/admin/user/${updateid}`, data, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("Data Delete");
          setw_update(true);
          setTimeout(() => {
            window.location.reload();
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setw_delete(false);
    setw_update(false);
    setw_add(false);
  };

  return (
    <div className="container">
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          <Button
            variant="contained"
            className="courseadd-btn"
            onClick={handleClickOpen2}
            color="error"
          >
            Add the User
          </Button>
          <div className="card-wapper">
            {user.map((data, index) => (
              <div className="card" key={index}>
                <h2 className="course-name">{data.position}</h2>
                <div className="text-box-card">
                  <h3 className="course-name">{data.username}</h3>
                  {/* <h3 className="course-name">{data.password}</h3> */}
                </div>
                {data.position === "Administrator" ? (
                  <img className="user-img-admin" src={admin} alt="" />
                ) : data.position === "Manager" ? (
                  <img className="user-img-manager" src={manager} alt="" />
                ) : data.position === "Coordinator" ? (
                  <img
                    className="user-img-supervisor"
                    src={supervisor}
                    alt=""
                  />
                ) : null}

                <div className="card-button-admin">
                  <div className="update-card-button">
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => handleClicUpdate(data.id)}
                    >
                      Update
                    </Button>
                  </div>
                  <div className="delete-card-button">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleClickdelete(data.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do You Want Delete This User?"}
            </DialogTitle>
            {/* <DialogContent>
              <DialogContentText id="alert-dialog-description">
                if you want delete this user
              </DialogContentText>
            </DialogContent> */}
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => deletebtn("Delete")} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open2}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do You Want Add The Course Name?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box
                  sx={{
                    "& > :not(style)": {
                      marginBottom: 1,
                      marginTop: 1,
                      minWidth: "45ch",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter The User E-mail"
                    variant="outlined"
                    value={username}
                    onChange={(e) => {
                      setusername(e.target.value);
                    }}
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Enter The User Password"
                    variant="outlined"
                    // type="number"
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">Rs.</InputAdornment>
                    //   ),
                    // }}
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{
                    "& > :not(style)": {
                      marginBottom: 1,
                      marginTop: 1,
                      minWidth: "62ch",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Select Position :
                    </InputLabel>
                    <Select
                      labelId="position"
                      id="demo-simple-select"
                      label="Select Position :"
                      value={position}
                      onChange={(e) => {
                        setposition(e.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value={"Administrator"}>Administrator</MenuItem>
                      <MenuItem value={"Manager"}>Manager</MenuItem>
                      <MenuItem value={"Coordinator"}>Coordinator</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => addbtn("Add")} autoFocus>
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open1}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do You Want Update This User?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      marginBottom: 1,
                      marginTop: 1,
                      minwidth: "45ch",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Enter The User E-mail"
                    variant="outlined"
                    value={c_username}
                    onChange={(e) => {
                      setc_username(e.target.value);
                    }}
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Enter The User Password"
                    variant="outlined"
                    // type="number"
                    value={c_password}
                    onChange={(e) => {
                      setc_password(e.target.value);
                    }}
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{
                    "& > :not(style)": {
                      marginBottom: 1,
                      marginTop: 1,
                      minWidth: "62ch",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Select Position :
                    </InputLabel>
                    <Select
                      labelId="position"
                      id="demo-simple-select"
                      label="Select Position :"
                      value={c_position}
                      onChange={(e) => {
                        setc_position(e.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value={"Administrator"}>Administrator</MenuItem>
                      <MenuItem value={"Manager"}>Manager</MenuItem>
                      <MenuItem value={"Coordinator"}>Coordinator</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => updatebtn("Update")} autoFocus>
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={w_delete}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              User Deleted
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_add}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              User Added
            </Alert>
          </Snackbar>
          <Snackbar
            open={w_update}
            autoHideDuration={1500}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
              User Updated
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}
