import React, { useEffect, useState } from "react";
import "../../Css/Admin/student.css";
import "../../Css/Admin/admin_home.css";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import Header from "../../Components/Admin/header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Port from "../../port";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Admin/Loader";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import ReceiptIcon from "@mui/icons-material/Receipt";

function PaymentHistory() {
  const navigate = useNavigate();
  const params = useParams();
  const token = localStorage.getItem("token");
  const nic = params.nic;
  const [courseDetails, setCourseDetails] = useState([]);
  const [uniDetails, setUniDetails] = useState([]);
  const [active, setActive] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setActive(false);
    }, 3000);

    axios
      .get(`http://${Port}/admin/course/payment/detail/${nic}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setCourseDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`http://${Port}/admin/university/payment/detail/${nic}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setUniDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, nic]);

  return (
    <div className="container">
      <Loader open={active} />
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}
          <div className="student-detail-wrapper clearfix">
            <ArrowBackIcon
              style={{ float: "left", marginTop: "20px", marginLeft: "20px" }}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="payment-header">
            <AvTimerIcon style={{ marginLeft: "10px", color: "red" }} />
            <p style={{ marginTop: "2px" }}> University Payment History</p>
          </div>
          {uniDetails.map((detail, index) => (
            <Payment
              pDate={detail.deposited_date}
              status={detail.status}
              bank={detail.bank_name}
              branch={detail.branch_name}
              amount={detail.deposited_amount}
              type={detail.type}
              message={detail.reject_message}
              slip={detail.image}
            />
          ))}

          <div className="payment-header">
            <AvTimerIcon style={{ marginLeft: "10px", color: "red" }} />
            <p style={{ marginTop: "2px" }}> Course Payment History</p>
          </div>
          {courseDetails.map((detail, index) => (
            <Payment
              pDate={detail.deposited_date}
              status={detail.status}
              bank={detail.bank_name}
              branch={detail.branch_name}
              amount={detail.deposited_amount}
              type={detail.type}
              message={detail.reject_message}
              slip={detail.image}
            />
          ))}

          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

function Payment(props) {
  const [isReject, setIsReject] = useState(false);
    const status = props.status;
      const token = localStorage.getItem("token");
  useEffect(() => {
    if (props.message) {
      setIsReject(true);
    }
  }, [props.message]);
  const paymentSlipHandler = () => {
    axios({
      url: `http://${Port}/admin/student/paymnet/slip/${props.slip}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          // setOpen(false);
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  };

  return (
    <div className="payment-container">
      <div className="payment-container-header clearfix">
        <p style={{ float: "left", fontSize: "14px"}}>
          Deposited Date : {props.pDate}
        </p>
        <p
          style={
            status === "Pending"
              ? {
                  float: "right",
                  fontSize: "14px",
                  color: "rgb(255, 127, 0)",
                marginRight: "50px",
                  
                }
              : status === "Approved"
              ? {
                  float: "right",
                  fontSize: "14px",
                  color: "green",
                  marginRight: "50px",
                }
              : {
                  float: "right",
                  fontSize: "14px",
                  color: "red",
                  marginRight: "50px",
                }
          }
        >
          Status : {props.status}
        </p>
      </div>
      <div className="payment-container-body clearfix">
        <div style={{ width: "80%" }}>
          <div className="payment-container-section">
            <p>Bank Name : </p>
            <p style={{fontWeight:"bold"}}>{props.bank}</p>
          </div>
          <div className="payment-container-section">
            <p>Branch Name : </p>
            <p style={{fontWeight:"bold"}}>{props.branch}</p>
          </div>
          <div className="payment-container-section">
            <p>Diposited Amount : </p>
            <p style={{fontWeight:"bold"}}>{"Rs " + parseInt(props.amount).toFixed(2)}</p>
          </div>
          <div className="payment-container-section">
            <p>Payment Type : </p>
            <p style={{fontWeight:"bold"}}>{props.type}</p>
          </div>
          {isReject && (
            <div className="payment-container-section" style={{ color: "red" }}>
              <p>Reject Message : </p>
              <p style={{fontWeight:"bold"}}>{props.message}</p>
            </div>
          )}
        </div>
        <div style={{ width: "20%", textAlign: "center" }}>
          <ReceiptIcon
            style={{ color: "green", cursor: "pointer" }}
            onClick={paymentSlipHandler}
          />
          <p>Payment Slip</p>
        </div>
      </div>
    </div>
  );
}

export default PaymentHistory;
