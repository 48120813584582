import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Port from "../../port";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import Loader from "../../Components/Admin/Loader";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import "../../Css/Admin/Requsetpage.css";
import SignatureCanvas from "react-signature-canvas";
import FormHelperText from "@mui/material/FormHelperText";
import Terms_Conditions from "../../Terms & Conditions File/Students Terms & Conditions - CCL Academy - Pearson BTEC.pdf";
// import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function getSteps() {
  return [
    "Interested Course",
    "Basic Information",
    "Contact Details",
    "Next Of Kin Contact Details",
    "Education",
    "Work Experience",
    "Social Profile Links",
    "Payment Details",
    "Personal Statement",
  ];
}
const InterestedCourse = () => {
  const { control } = useFormContext();
  const [course, setcourse] = useState("");

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const [open9, setOpen9] = React.useState(false);
  const [open10, setOpen10] = React.useState(false);

  const [options, setOptions] = React.useState([]);
  const [options1, setOptions1] = React.useState([]);
  const [options2, setOptions2] = React.useState([]);
  const [options3, setOptions3] = React.useState([]);
  const [options4, setOptions4] = React.useState([]);
  const [options5, setOptions5] = React.useState([]);
  const [options6, setOptions6] = React.useState([]);
  const [options7, setOptions7] = React.useState([]);
  const [options8, setOptions8] = React.useState([]);
  const [options9, setOptions9] = React.useState([]);
  const [options10, setOptions10] = React.useState([]);

  const loading = open && options.length === 0;
  const loading1 = open1 && options1.length === 0;
  const loading2 = open2 && options2.length === 0;
  const loading3 = open3 && options3.length === 0;
  const loading4 = open4 && options4.length === 0;
  const loading5 = open5 && options5.length === 0;
  const loading6 = open6 && options6.length === 0;
  const loading7 = open7 && options7.length === 0;
  const loading8 = open8 && options8.length === 0;
  const loading9 = open9 && options9.length === 0;
  const loading10 = open10 && options10.length === 0;

  const [sub_count, setsub_count] = useState("");

  const [sub1, setsub1] = useState(true);

  const [sub1_name, setsub1_name] = useState(null);
  const [sub2_name, setsub2_name] = useState(null);
  const [sub3_name, setsub3_name] = useState(null);
  const [sub4_name, setsub4_name] = useState(null);
  const [sub5_name, setsub5_name] = useState(null);
  const [sub6_name, setsub6_name] = useState(null);
  const [sub7_name, setsub7_name] = useState(null);
  const [sub8_name, setsub8_name] = useState(null);
  const [sub9_name, setsub9_name] = useState(null);
  const [sub10_name, setsub10_name] = useState(null);

  //Course Textfeild
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/course`)
          .then((response) => response.json())
          .then((data) => {
            setOptions(data);
            // setsub_count(data)
            // console.log(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    fetch(`http://${Port}/request/course/${course}`)
      .then((response) => response.json())
      .then((data) => {
        setsub_count(data.subject);
        // console.log(data.subject);
      });
  }, [course]);

  //Optional Subject 1 Textfeild
  React.useEffect(() => {
    let active = true;
    if (course !== "") {
      setsub1(false);
      if (!loading1) {
        return undefined;
      }
      (async () => {
        await sleep(1e3); // For demo purposes.

        if (active) {
          fetch(`http://${Port}/request/subject/${course}`)
            .then((response) => response.json())
            .then((data) => {
              setOptions1(data);
            });
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [loading1, course]);

  React.useEffect(() => {
    if (!open1) {
      setOptions1([]);
    }
  }, [open1]);

  //Optional Subject 2 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading2) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions2(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading2, course]);

  React.useEffect(() => {
    if (!open2) {
      setOptions2([]);
    }
  }, [open2]);

  //Optional Subject 3 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading3) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions3(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading3, course]);

  React.useEffect(() => {
    if (!open3) {
      setOptions3([]);
    }
  }, [open3]);

  //Optional Subject 4 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading4) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions4(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading4, course]);

  React.useEffect(() => {
    if (!open4) {
      setOptions4([]);
    }
  }, [open4]);

  //Optional Subject 5 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading5) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions5(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading5, course]);

  React.useEffect(() => {
    if (!open5) {
      setOptions5([]);
    }
  }, [open5]);

  //Optional Subject 6 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading6) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions6(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading6, course]);

  React.useEffect(() => {
    if (!open6) {
      setOptions6([]);
    }
  }, [open6]);

  //Optional Subject 7 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading7) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions7(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading7, course]);

  React.useEffect(() => {
    if (!open7) {
      setOptions7([]);
    }
  }, [open7]);

  //Optional Subject 8 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading8) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions8(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading8, course]);

  React.useEffect(() => {
    if (!open8) {
      setOptions8([]);
    }
  }, [open8]);

  //Optional Subject 9 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading9) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions9(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading9, course]);

  React.useEffect(() => {
    if (!open9) {
      setOptions9([]);
    }
  }, [open9]);

  //Optional Subject 10 Textfeild
  React.useEffect(() => {
    let active = true;
    if (!loading10) {
      return undefined;
    }
    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        fetch(`http://${Port}/request/subject/${course}`)
          .then((response) => response.json())
          .then((data) => {
            setOptions10(data);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading10, course]);

  React.useEffect(() => {
    if (!open10) {
      setOptions10([]);
    }
  }, [open10]);
  // useEffect(() => {
  //   if (course !== "") {
  //     axios
  //       .get(`http://${Port}/request/course/fee/${course}`)
  //       .then((res) => {
  //         setcourse_fee(res.data[0].c_fee);
  //         console.log(res.data[0].c_fee);
  //       })
  //       .catch((err) => {});
  //   }
  // }, [course]);

  return (
    <>
      <div className="Form">
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "97%", minWidth: "56.3ch" },
          }}
        >
          <Controller
            control={control}
            name="i_course"
            render={({ field }) => (
              <Autocomplete
                id="i_course"
                sx={{ width: 300 }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                getOptionLabel={(option) => option.c_name}
                onChange={(e, value) => {
                  field.onChange(value.c_name);
                  setcourse(value.c_name);
                  // console.log(field.title);
                }}
                options={options}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    {...field}
                    // value={course}
                    label="Select The Interested Course :"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
          />
          {sub_count >= 1 ? (
            <>
              <h5 className="optional">Optional Subject 1</h5>
              <Controller
                control={control}
                name="sub_1"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_1"
                    disabled={sub1}
                    sx={{ width: 300 }}
                    open={open1}
                    onOpen={() => {
                      setOpen1(true);
                    }}
                    onClose={() => {
                      setOpen1(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options1.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options1.find((sub) => sub.id === sub9_name) ||
                        option === options1.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option1) => option1.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub1_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options1}
                    loading={loading1}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        {...field}
                        // value={course}
                        label="Select The Optional Subject 1 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading1 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 2 ? (
            <>
              <h5 className="optional">Optional Subject 2</h5>
              <Controller
                control={control}
                name="sub_2"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_2"
                    // disabled={sub2}
                    sx={{ width: 300 }}
                    open={open2}
                    onOpen={() => {
                      setOpen2(true);
                    }}
                    onClose={() => {
                      setOpen2(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options2.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options2.find((sub) => sub.id === sub9_name) ||
                        option === options2.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option2) => option2.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub2_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options2}
                    loading={loading2}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 2 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading2 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 3 ? (
            <>
              <h5 className="optional">Optional Subject 3</h5>
              <Controller
                control={control}
                name="sub_3"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_3"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open3}
                    onOpen={() => {
                      setOpen3(true);
                    }}
                    onClose={() => {
                      setOpen3(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options3.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options3.find((sub) => sub.id === sub9_name) ||
                        option === options3.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option3) => option3.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub3_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options3}
                    loading={loading3}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 3 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading3 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 4 ? (
            <>
              <h5 className="optional">Optional Subject 4</h5>
              <Controller
                control={control}
                name="sub_4"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_4"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open4}
                    onOpen={() => {
                      setOpen4(true);
                    }}
                    onClose={() => {
                      setOpen4(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options4.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options4.find((sub) => sub.id === sub9_name) ||
                        option === options4.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option4) => option4.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub4_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options4}
                    loading={loading4}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 4 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading4 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 5 ? (
            <>
              <h5 className="optional">Optional Subject 5</h5>
              <Controller
                control={control}
                name="sub_5"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_5"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open5}
                    onOpen={() => {
                      setOpen5(true);
                    }}
                    onClose={() => {
                      setOpen5(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options5.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options5.find((sub) => sub.id === sub9_name) ||
                        option === options5.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option5) => option5.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub5_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options5}
                    loading={loading5}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 5 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading5 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 6 ? (
            <>
              <h5 className="optional">Optional Subject 6</h5>
              <Controller
                control={control}
                name="sub_6"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_6"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open6}
                    onOpen={() => {
                      setOpen6(true);
                    }}
                    onClose={() => {
                      setOpen6(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options6.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options6.find((sub) => sub.id === sub9_name) ||
                        option === options6.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option6) => option6.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub6_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options6}
                    loading={loading6}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 6 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading6 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 7 ? (
            <>
              <h5 className="optional">Optional Subject 7</h5>
              <Controller
                control={control}
                name="sub_7"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_7"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open7}
                    onOpen={() => {
                      setOpen7(true);
                    }}
                    onClose={() => {
                      setOpen7(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options7.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options7.find((sub) => sub.id === sub9_name) ||
                        option === options7.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option7) => option7.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub7_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options7}
                    loading={loading7}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 7 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading7 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 8 ? (
            <>
              <h5 className="optional">Optional Subject 8</h5>
              <Controller
                control={control}
                name="sub_8"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_8"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open8}
                    onOpen={() => {
                      setOpen8(true);
                    }}
                    onClose={() => {
                      setOpen8(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options8.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options8.find((sub) => sub.id === sub9_name) ||
                        option === options8.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option8) => option8.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub8_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options8}
                    loading={loading8}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 8 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading8 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 9 ? (
            <>
              <h5 className="optional">Optional Subject 9</h5>
              <Controller
                control={control}
                name="sub_9"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_9"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open9}
                    onOpen={() => {
                      setOpen9(true);
                    }}
                    onClose={() => {
                      setOpen9(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options9.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options9.find((sub) => sub.id === sub9_name) ||
                        option === options9.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option9) => option9.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub9_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options9}
                    loading={loading9}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 9 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading9 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
          {sub_count >= 10 ? (
            <>
              <h5 className="optional">Optional Subject 10</h5>
              <Controller
                control={control}
                name="sub_10"
                render={({ field }) => (
                  <Autocomplete
                    id="sub_10"
                    // disabled={sub3}
                    sx={{ width: 300 }}
                    open={open10}
                    onOpen={() => {
                      setOpen10(true);
                    }}
                    onClose={() => {
                      setOpen10(false);
                    }}
                    getOptionDisabled={
                      (option) =>
                        option ===
                          options10.find((sub) => sub.id === sub1_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub2_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub3_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub4_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub5_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub6_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub7_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub8_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub9_name) ||
                        option ===
                          options10.find((sub) => sub.id === sub10_name)
                      // console.log(options2)
                    }
                    getOptionLabel={(option10) => option10.sub_name}
                    onChange={(e, value) => {
                      field.onChange(value.sub_name);
                      setsub10_name(value.id);
                      // console.log(field.title);
                    }}
                    options={options10}
                    loading={loading10}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        {...field}
                        required
                        // value={course}
                        label="Select The Optional Subject 10 :"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading10 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </>
          ) : null}
        </Box>
      </div>
    </>
  );
};
const BasicInformation = () => {
  const { control } = useFormContext();
  return (
    <>
      <div className="Form">
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "98.5%", minWidth: "56.3ch" },
            // "& > :not(style)": { m: 1, width: "56.3ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="f_name"
            render={({ field }) => (
              <TextField
                id="f_name"
                label="Enter the Full Name :"
                variant="outlined"
                fullWidth
                // required
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "98.5%", minWidth: "56.3ch" },
            "& > :not(style)": { m: 1, width: "56.3ch" },
            "& p": {
              color: "red",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="fi_name"
            render={({ field }) => (
              <TextField
                id="fi_name"
                label="Enter the First Name :"
                variant="outlined"
                helperText="Maximum 20 Characters including spaces"
                fullWidth
                inputProps={{ maxLength: 20 }}
                // required
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="se_name"
            render={({ field }) => (
              <TextField
                id="se_name"
                label="Enter the Last Name :"
                helperText="Maximum 30 Characters including spaces"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 30 }}
                // required
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                id="name"
                label="Enter The Name With Initials :"
                helperText="This will be displayed on your Pearson Certificate"
                variant="outlined"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                // required
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="d_o_birth"
            render={({ field }) => (
              <TextField
                id="d_o_birth"
                label="Enter the Date Of Birth :"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                // required
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="gender"
            render={({ field }) => (
              <>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Select Your Gender :
                  </InputLabel>
                  <Select
                    labelId="gender"
                    id="demo-simple-select"
                    label="Select Your Gender :"
                    {...field}
                  >
                    <MenuItem value={"M"}>Male</MenuItem>
                    <MenuItem value={"F"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          />

          <Controller
            control={control}
            name="citizenship"
            render={({ field }) => (
              <TextField
                id="citizenship"
                label="Enter The Country of Citizenship :"
                variant="outlined"
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "55ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="nic_o_passport"
            render={({ field }) => (
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Select NIC or Passport :
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  {...field}
                >
                  <FormControlLabel
                    value="NIC"
                    control={<Radio />}
                    label="NIC"
                  />
                  <FormControlLabel
                    value="Passport"
                    control={<Radio />}
                    label="Passport"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box
          sx={{
            // "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            "& > :not(style)": { m: 1, width: "56.3ch" },
            "& p": {
              color: "red",
            },
          }}
        >
          <Controller
            control={control}
            name="n_o_p_number"
            render={({ field }) => (
              <TextField
                id="n_o_p_number"
                label="Enter The NIC/Passport Number :"
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="ms_id"
            render={({ field }) => (
              <TextField
                id="ms_id"
                label="Enter The MS Teams ID :"
                helperText="(Only for CCL Students and Alumni)"
                variant="outlined"
                // required
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            "& p": {
              color: "red",
            },
            // "& > :not(style)": { m: 1, width: "56.3ch" },
          }}
        >
          <Controller
            control={control}
            name="s_photo"
            render={({ field }) => (
              <TextField
                id="s_photo"
                type="file"
                label="Upload The Your Photo :"
                helperText="Please Upload Photo Passport Size and .JPG Format Only"
                variant="outlined"
                accept="image/.JPG"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: ".JPG",
                }}
                onChange={(e) => {
                  field.onChange(e.target.files[0]);
                }}
                fullWidth
                required
              />
            )}
          />
          <Controller
            control={control}
            name="n_or_p_pic"
            render={({ field }) => (
              <TextField
                id="n_or_p_pic"
                type="file"
                label="Upload The Your NIC or Passpoort Scan Copy :"
                helperText="Please Upload Photo .JPG Format Only"
                variant="outlined"
                accept="image/.JPG"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: ".JPG",
                }}
                onChange={(e) => {
                  field.onChange(e.target.files[0]);
                }}
                fullWidth
                required
              />
            )}
          />
        </Box>
      </div>
    </>
  );
};
const ContactDetails = () => {
  const { control } = useFormContext();
  return (
    <>
      <div className="Form">
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, width: "98.5%", minWidth: "56.3ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                id="email"
                label="Enter The Your E-mail :"
                type="email"
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "56.9ch" },
            // "& > :not(style)": { m: 1, width: "97%", minWidth: "56.3ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="mobile"
            render={({ field }) => (
              <TextField
                id="mobile"
                label="Enter the Mobile Number :"
                type="number"
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="l_line"
            render={({ field }) => (
              <TextField
                id="l_line"
                type="number"
                label="Enter the Land Line Number :"
                variant="outlined"
                // required
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, width: "98.5%", minWidth: "56.3ch" },
            "& p": {
              color: "red",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="p_address"
            render={({ field }) => (
              <TextField
                id="p_address"
                label="Enter The Permanent Address :"
                variant="outlined"
                fullWidth
                // required
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="c_address"
            render={({ field }) => (
              <TextField
                id="c_address"
                label="Enter The Correspondence Address :"
                helperText="If different from permanent address"
                variant="outlined"
                fullWidth
                // required
                {...field}
              />
            )}
          />
        </Box>
      </div>
    </>
  );
};
const NextOfKinContactDetails = () => {
  const { control } = useFormContext();
  return (
    <>
      <div className="Form">
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, width: "98.5%", minWidth: "56.3ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="k_c_name"
            render={({ field }) => (
              <TextField
                id="k_c_name"
                label="Enter The Next Of Kin Name :"
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "56.9ch" },
            // "& > :not(style)": { m: 1, width: "97%", minWidth: "56.3ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="k_c_mobile"
            render={({ field }) => (
              <TextField
                id="k_c_mobile"
                label="Enter The Next Of Kin Mobile Number :"
                type="number"
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="k_c_l_line"
            render={({ field }) => (
              <TextField
                id="k_c_l_line"
                type="number"
                label="Enter The Next Of Kin Land Line Number :"
                variant="outlined"
                // required
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, width: "98.5%", minWidth: "56.3ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Controller
            control={control}
            name="k_c_p_address"
            render={({ field }) => (
              <TextField
                id="k_c_p_address"
                label="Enter The Next Of Kin Permanent Address :"
                variant="outlined"
                fullWidth
                // required
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="k_c_relationship"
            render={({ field }) => (
              <TextField
                id="k_c_relationship"
                label="Enter The Next Of Kin Relationship :"
                variant="outlined"
                fullWidth
                // required
                {...field}
              />
            )}
          />
        </Box>
      </div>
    </>
  );
};
const Education = () => {
  const { control } = useFormContext();

  const [oLCertificates, setoLCertificates] = React.useState(false);
  const [aLCertificates, setaLCertificates] = React.useState(false);
  const [iELTSCertificates, setiELTSCertificates] = React.useState(false);
  const [academicWorkPortfolio, setacademicWorkPortfolio] =
    React.useState(false);
  const [otherCertificates, setotherCertificates] = React.useState(false);

  return (
    <>
      <div className="Form">
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "56.9ch" },
            // "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            "& p": {
              color: "red",
            },
          }}
        >
          <Controller
            control={control}
            name="h_e_qualification"
            render={({ field }) => (
              <TextField
                id="h_e_qualification"
                label="Enter The Highest Educational Qualification :"
                variant="outlined"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="ielts_score"
            render={({ field }) => (
              <TextField
                id="ielts_score"
                label="Enter The IELTS Score :"
                type="number"
                helperText="If available"
                variant="outlined"
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            "& p": {
              color: "red",
            },
          }}
        >
          <Controller
            control={control}
            name="oLCertificates"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={oLCertificates}
                      onChange={(e) => {
                        setoLCertificates(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="O/L Certificates"
                />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="aLCertificates"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={aLCertificates}
                      onChange={(e) => {
                        setaLCertificates(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="A/L Certificate"
                  // helperText="Not required for Level 3 registrants"
                />
                <FormHelperText>
                  (Not required for Level 3 registrants)
                </FormHelperText>
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="iELTSCertificates"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={iELTSCertificates}
                      onChange={(e) => {
                        setiELTSCertificates(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="IELTS Certificate"
                />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="academicWorkPortfolio"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={academicWorkPortfolio}
                      onChange={(e) => {
                        setacademicWorkPortfolio(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Academic Work/Portfolio Certificate"
                />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="otherCertificates"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherCertificates}
                      onChange={(e) => {
                        setotherCertificates(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Other Certificates (relevant to interior design)"
                />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="studycertificates"
            render={({ field }) => (
              <div className="textfeild_sub">
                <TextField
                  id="workcertificates"
                  type="file"
                  label="Upload the Certificates :"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: ".zip",
                  }}
                  onChange={(e) => {
                    field.onChange(e.target.files[0]);
                  }}
                  helperText="Please Upload .zip Format Only"
                  fullWidth
                  required
                />
              </div>
            )}
          />
        </Box>
      </div>
    </>
  );
};
const EmploymentDetails = () => {
  const { control } = useFormContext();
  const [WorkExperiencLetter, setWorkExperiencLetter] = React.useState(false);
  const [CV, setCV] = React.useState(false);
  const [WorkPortfolio, setWorkPortfolio] = React.useState(false);
  return (
    <>
      <div className="Form">
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            "& p": {
              color: "red",
            },
          }}
        >
          <Controller
            control={control}
            name="e_details"
            render={({ field }) => (
              <TextField
                id="e_details"
                label="Enter The Employment Details :"
                // placeholder="Date(from) to Date(to)_Employer_Role/Designation_Duties"
                helperText="Ex: 2022-01-01 to 2022-02-01_CCL Studio_Interior Design_Designer"
                fullWidth
                multiline
                rows={6}
                variant="outlined"
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="workExperiencLetter"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={WorkExperiencLetter}
                      onChange={(e) => {
                        setWorkExperiencLetter(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Work Experience Letter"
                />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="cV"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={CV}
                      onChange={(e) => {
                        setCV(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="CV"
                />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="workPortfolio"
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={WorkPortfolio}
                      onChange={(e) => {
                        setWorkPortfolio(e.target.checked);
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Work Portfolio"
                />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="workcertificates"
            render={({ field }) => (
              <div className="textfeild_sub">
                <TextField
                  id="workcertificates"
                  type="file"
                  label="Upload the Certificates :"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: ".zip",
                  }}
                  onChange={(e) => {
                    field.onChange(e.target.files[0]);
                  }}
                  helperText="Please Upload .zip Format Only"
                  fullWidth
                  // required
                />
              </div>
            )}
          />
        </Box>
      </div>
    </>
  );
};
const SocialProfileLinks = () => {
  const { control } = useFormContext();

  return (
    <>
      <div className="Form">
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            // "& p": {
            //   color: "red",
            // },
          }}
        >
          <Controller
            control={control}
            name="fb_link"
            render={({ field }) => (
              <TextField
                id="fb_link"
                label="Enter The Facebook Account Link :"
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="i_link"
            render={({ field }) => (
              <TextField
                id="i_link"
                label="Enter The Instagram Account Link :"
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="l_i_link"
            render={({ field }) => (
              <TextField
                id="l_i_link"
                label="Enter The LinkedIn Account Link :"
                variant="outlined"
                fullWidth
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="o_link"
            render={({ field }) => (
              <TextField
                id="o_link"
                label="Enter The Other (Behance, YouTube,etc) Account Link :"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                {...field}
              />
            )}
          />
        </Box>
      </div>
    </>
  );
};
const PaymentDetails = () => {
  const { control } = useFormContext();

  return (
    <>
      <div className="Form">
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "56.9ch" },
            // "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            // "& p": {
            //   color: "red",
            // },
          }}
        >
          <Controller
            control={control}
            name="p_bank"
            render={({ field }) => (
              <>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Select The Bank :
                  </InputLabel>
                  <Select
                    labelId="p_bank"
                    id="demo-simple-select"
                    label="Select The Bank :"
                    {...field}
                  >
                    <MenuItem value={"Amana Bank PLC"}>
                      Amana Bank PLC{" "}
                    </MenuItem>
                    <MenuItem value={"Bank of Ceylon"}>Bank of Ceylon</MenuItem>
                    <MenuItem value={"Bank of China Ltd"}>
                      Bank of China Ltd
                    </MenuItem>
                    <MenuItem value={"Cargills Bank Ltd"}>
                      Cargills Bank Ltd
                    </MenuItem>
                    <MenuItem value={"Citibank, N.A."}>Citibank, N.A.</MenuItem>
                    <MenuItem value={"Commercial Bank of Ceylon PLC"}>
                      Commercial Bank of Ceylon PLC
                    </MenuItem>
                    <MenuItem value={"Deutsche Bank AG, Colombo Branch"}>
                      Deutsche Bank AG, Colombo Branch
                    </MenuItem>
                    <MenuItem value={"DFCC Bank PLC"}>DFCC Bank PLC</MenuItem>
                    <MenuItem value={"Habib Bank Ltd"}>Habib Bank Ltd</MenuItem>
                    <MenuItem value={"Hatton National Bank PLC"}>
                      Hatton National Bank PLC
                    </MenuItem>
                    <MenuItem value={"Indian Bank"}>Indian Bank</MenuItem>
                    <MenuItem value={"Indian Overseas Bank"}>
                      Indian Overseas Bank
                    </MenuItem>
                    <MenuItem value={"MCB Bank Ltd"}>MCB Bank Ltd</MenuItem>
                    <MenuItem value={"National Development Bank PLC"}>
                      National Development Bank PLC
                    </MenuItem>
                    <MenuItem value={"Nations Trust Bank PLC"}>
                      Nations Trust Bank PLC
                    </MenuItem>
                    <MenuItem value={"Pan Asia Banking Corporation PLC"}>
                      Pan Asia Banking Corporation PLC
                    </MenuItem>
                    <MenuItem value={"People's Bank"}>People's Bank</MenuItem>
                    <MenuItem value={"Public Bank Berhad"}>
                      Public Bank Berhad
                    </MenuItem>
                    <MenuItem value={"Sampath Bank PLC"}>
                      Sampath Bank PLC
                    </MenuItem>
                    <MenuItem value={"Seylan Bank PLC"}>
                      Seylan Bank PLC
                    </MenuItem>
                    <MenuItem value={"Standard Chartered Bank"}>
                      Standard Chartered Bank
                    </MenuItem>
                    <MenuItem value={"State Bank of India"}>
                      State Bank of India
                    </MenuItem>
                    <MenuItem
                      value={
                        "The Hongkong & Shanghai Banking Corporation Ltd (HSBC)"
                      }
                    >
                      The Hongkong & Shanghai Banking Corporation Ltd (HSBC)
                    </MenuItem>
                    <MenuItem value={"Union Bank of Colombo PLC"}>
                      Union Bank of Colombo PLC
                    </MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          />
          <Controller
            control={control}
            name="p_amount"
            render={({ field }) => (
              <TextField
                id="p_amount"
                label="Enter the registration payment amount :"
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            // "& > :not(style)": { m: 1, width: "56.3ch" },
            "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            "& p": {
              color: "red",
            },
          }}
        >
          <Controller
            control={control}
            name="p_slip"
            render={({ field }) => (
              <TextField
                id="p_slip"
                label="Upload the registration payment slip :"
                variant="outlined"
                helperText="Please Upload Photo .JPG Format Only"
                fullWidth
                type="file"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: ".JPG",
                }}
                // inputProps={{
                //   multiple: true,
                // }}
                onChange={(e) => {
                  field.onChange(e.target.files[0]);
                }}
                required
              />
            )}
          />
        </Box>
      </div>
    </>
  );
};
const PersonalStatement = () => {
  const { control } = useFormContext();

  let sig = useRef({});

  const [s_type, sets_type] = useState("");
  // const [s_value, sets_value] = useState("");
  // const [s_value_type, sets_value_type] = useState("");
  const [w_signature, setw_signature] = useState(false);
  const [d_signature, setd_signature] = useState(false);

  useEffect(() => {
    if (s_type === "w_signature") {
      // console.log(control._fields.s_type._f.value);
      setw_signature(true);
      setd_signature(false);
    } else if (s_type === "d_signature") {
      setd_signature(true);
      setw_signature(false);
    }
  }, [s_type]);

  // useEffect(() => {
  //   if (s_value_type !== "") {
  //     sets_value(s_value_type);
  //     // console.log(s_value_type);
  //   } else if (s_value_type === "") {
  //     console.log("not");
  //   }
  // }, [s_value_type]);

  function clear() {
    sig.current.clear();
  }
  // function save() {
  //   data = sig.current.toDataURL();
  //   sets_value_type(data);
  // }
  const viewhandler = () => {
    window.open(Terms_Conditions);
  };
  return (
    <>
      <div className="Form">
        <div className="personal_topic">PERSONAL STATEMENT</div>
        <div className="personal_sub">
          "I declare to the best of my knowledge that the information submitted
          in this form is correct and I agree to the terms and conditions and
          the recruitment, registration and certification policies of CADD
          Centre Lanka (CCL Academy)"
        </div>
        <div className="personal_sub personal_sub_link" onClick={viewhandler}>
          View Students Terms & Conditions
        </div>
        <Box
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
            // "& > :not(style)": { m: 1, maxWidth: "98.5%", minWidth: "56.3ch" },
            // "& p": {
            //   color: "red",
            // },
          }}
        >
          <Controller
            control={control}
            name="s_date"
            render={({ field }) => (
              <TextField
                id="s_date"
                label="Select The Date :"
                variant="standard"
                InputProps={{
                  readOnly: true,
                }}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="s_type"
            render={({ field }) => (
              <div className="s_type">
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Signature Type :
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={s_type}
                    onChange={(e) => {
                      sets_type(e.target.value);
                      field.onChange(e.target.value);
                    }}
                    // {...field}
                  >
                    <FormControlLabel
                      value="w_signature"
                      control={<Radio />}
                      label="Write Signature"
                    />
                    <FormControlLabel
                      value="d_signature"
                      control={<Radio />}
                      label="Draw Signature"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          />
        </Box>
        {d_signature ? (
          <>
            <Controller
              control={control}
              name="d_signature"
              render={({ field }) => (
                <div className="signature">
                  <SignatureCanvas
                    ref={sig}
                    penColor="rgb(255, 255, 255)"
                    backgroundColor="black"
                    canvasProps={{
                      // width: 1040,
                      height: 300,
                      className: "sigCanvas",
                    }}
                  />
                </div>
              )}
            />
            <div className="signature_button">
              <Button variant="text" onClick={clear}>
                Clear
              </Button>
              {/* <Button variant="text" onClick={save}>
                OK
              </Button> */}
            </div>
          </>
        ) : null}
        {w_signature ? (
          <Box
            sx={{
              // "& > :not(style)": { m: 1, width: "56.3ch" },
              "& > :not(style)": {
                m: 1,
                maxWidth: "98.5%",
                minWidth: "56.3ch",
              },
              // "& p": {
              //   color: "red",
              // },
            }}
          >
            <Controller
              control={control}
              name="w_signature"
              render={({ field }) => (
                <TextField
                  id="w_signature"
                  label="Enter The Signature :"
                  variant="standard"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Box>
        ) : null}
      </div>
    </>
  );
};

function getStepContent(step) {
  switch (step) {
    case 0:
      return <InterestedCourse />;
    case 1:
      return <BasicInformation />;
    case 2:
      return <ContactDetails />;
    case 3:
      return <NextOfKinContactDetails />;
    case 4:
      return <Education />;
    case 5:
      return <EmploymentDetails />;
    case 6:
      return <SocialProfileLinks />;
    case 7:
      return <PaymentDetails />;
    case 8:
      return <PersonalStatement />;
    default:
      return "unknown step";
  }
}

export default function Requset_form() {
  const currentdate = new Date();
  const requestmonth = new Date();
  // const zip = require("jszip")();

  // const [course, setcourse] = useState("");
  // const [coursefee, setcoursefee] = useState("");

  var newdate =
    currentdate.getFullYear() +
    "-" +
    (currentdate.getMonth() + 1) +
    "-" +
    currentdate.getDate();

  const month = requestmonth.getMonth();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const methods = useForm({
    defaultValues: {
      i_course: "", // Interested Course
      sub_1: "", //Subject 1
      sub_2: "", //Subject 2
      sub_3: "", //Subject 3
      sub_4: "", //Subject 4
      sub_5: "", //Subject 5
      sub_6: "", //Subject 6
      sub_7: "", //Subject 7
      sub_8: "", //Subject 8
      sub_9: "", //Subject 9
      sub_10: "", //Subject 10
      f_name: "", // Full Name
      fi_name: "", // First Name
      se_name: "", // Second Name
      name: "", // Name
      d_o_birth: "", // Date Of Birth
      gender: "", // Gender
      citizenship: "", // Citizenship
      nic_o_passport: "", // NIC Or Passpoart Type
      n_o_p_number: "", // NIC Or Passpoart Number
      ms_id: "", // Microsoft Teams ID
      s_photo: "", // Student Photo
      n_or_p_pic: "", // NIC o Passport Scan Copy
      email: "", // E-mail
      mobile: "", // Mobile Number
      l_line: "", // Landline Number
      p_address: "", // Permanent Address
      c_address: "", // Correspondence Address
      k_c_name: "", // Next Of Kin Name
      k_c_mobile: "", // Next Of Kin Mobile Number
      k_c_l_line: "", // Next Of Kin Land Line Number
      k_c_p_address: "", // Next Of Kin Permanent Address
      k_c_relationship: "", // Next Of Kin Relationship
      h_e_qualification: "", // Highest Educational Qualification
      ielts_score: "", // IELTS Score
      oLCertificates: "", // O/L Education Certificates
      aLCertificates: "", // A/L Education Certificates
      iELTSCertificates: "", // IELTS Education Certificates
      academicWorkPortfolio: "", // Academic Work/Portfolio Certificates
      otherCertificates: "", // Other Certificates
      studycertificates: "", //study certificates file
      e_details: "", // Employment Details
      workExperiencLetter: "", // Work Experience Letter
      cV: "", // CV
      workPortfolio: "", // WorkPortfolio
      workcertificates: "", // student certificates files
      fb_link: "", // Facebook Account Link
      i_link: "", // Instagram Account Link
      l_i_link: "", // LinkedIn Account Link
      o_link: "", // Other Account Link
      p_bank: "", // Bank
      p_amount: "", // Payment Amount
      p_slip: "", // Payment Splip
      s_date: newdate, // Submited Date
      s_type: "", // Signature Type
      w_signature: "", // Write Signature
      s_month: months[month], // Submit month
    },
  });

  const [w_i_course, setw_i_course] = useState(false);
  const [w_f_name, setw_f_name] = useState(false);
  const [w_fi_name, setw_fi_name] = useState(false);
  const [w_se_name, setw_se_name] = useState(false);
  const [w_name, setw_name] = useState(false);
  const [w_d_o_birth, setw_d_o_birth] = useState(false);
  const [w_gender, setw_gender] = useState(false);
  const [w_citizenship, setw_citizenship] = useState(false);
  const [w_nic_o_passport, setw_nic_o_passport] = useState(false);
  const [w_nic, setw_nic] = useState(false);
  const [w_ms_id, setw_ms_id] = useState(false);
  const [w_passport, setw_passport] = useState(false);
  const [w_email, setw_email] = useState(false);
  const [w_mobile, setw_mobile] = useState(false);
  const [w_p_address, setw_p_address] = useState(false);
  const [w_k_c_name, setw_k_c_name] = useState(false);
  const [w_k_c_mobile, setw_k_c_mobile] = useState(false);
  const [w_k_c_p_address, setw_k_c_p_address] = useState(false);
  const [w_k_c_relationship, setw_k_c_relationship] = useState(false);
  const [w_h_e_qualification, setw_h_e_qualification] = useState(false);
  const [w_p_bank, setw_p_bank] = useState(false);
  const [w_p_amount, setw_p_amount] = useState(false);
  const [w_s_type, setw_s_type] = useState(false);
  const [w_w_signature, setw_w_signature] = useState(false);

  const [s_datasend, sets_datasend] = useState(false);

  const [loader, setloader] = React.useState(false);

  const vertical = "top";
  const horizontal = "right";

  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps] = useState([]);
  const steps = getSteps();

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  //  useEffect(() => {
  //    if (course !== "") {
  //      axios
  //        .get(`http://${Port}/request/course/fee/${course}`)
  //        .then((res) => {
  //          setcoursefee(res.data[0].c_fee);
  //          console.log(res.data[0].c_fee);
  //        })
  //        .catch((err) => {
  //          console.log(err);
  //        });
  //    }
  //  }, [course]);

  const handleNext = (data) => {
    console.log(data);
    if (activeStep === 0) {
      if (data.i_course === "") {
        setw_i_course(true);
        setActiveStep(activeStep);
      } else {
        // setcourse(data.i_course);
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 1) {
      if (data.f_name === "") {
        setw_f_name(true);
        setActiveStep(activeStep);
      } else if (data.fi_name.length >= 20 || data.fi_name.length === 0) {
        // console.log(data.fi_name.length);
        setw_fi_name(true);
        setActiveStep(activeStep);
      } else if (data.se_name.length >= 30 || data.se_name.length === 0) {
        setw_se_name(true);
        setActiveStep(activeStep);
      } else if (data.name === "") {
        setw_name(true);
        setActiveStep(activeStep);
      } else if (data.d_o_birth === "") {
        setw_d_o_birth(true);
        setActiveStep(activeStep);
      } else if (data.gender === "") {
        setw_gender(true);
        setActiveStep(activeStep);
      } else if (data.citizenship === "") {
        setw_citizenship(true);
        setActiveStep(activeStep);
      } else if (data.nic_o_passport === "") {
        setw_nic_o_passport(true);
        setActiveStep(activeStep);
      } else if (data.nic_o_passport === "NIC") {
        if (
          !(data.n_o_p_number.length === 10 || data.n_o_p_number.length === 12)
        ) {
          setw_nic(true);
          setActiveStep(activeStep);
        } else if (data.ms_id === "") {
          // setw_ms_id(true);
          setActiveStep(activeStep + 1);
        } else {
          setActiveStep(activeStep + 1);
        }
      } else if (data.nic_o_passport === "Passport") {
        if (data.n_o_p_number === "") {
          setw_passport(true);
          setActiveStep(activeStep);
        } else if (data.ms_id === "") {
          // setw_ms_id(true);
          setActiveStep(activeStep + 1);
        } else {
          setActiveStep(activeStep + 1);
        }
      }
    } else if (activeStep === 2) {
      if (data.email === "") {
        setw_email(true);
        setActiveStep(activeStep);
      } else if (!(data.mobile.length === 10)) {
        setw_mobile(true);
        setActiveStep(activeStep);
      } else if (data.p_address === "") {
        setw_p_address(true);
        setActiveStep(activeStep);
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 3) {
      if (data.k_c_name === "") {
        setw_k_c_name(true);
        setActiveStep(activeStep);
      } else if (!(data.k_c_mobile.length === 10)) {
        setw_k_c_mobile(true);
        setActiveStep(activeStep);
      } else if (data.k_c_p_address === "") {
        setw_k_c_p_address(true);
        setActiveStep(activeStep);
      } else if (data.k_c_relationship === "") {
        setw_k_c_relationship(true);
        setActiveStep(activeStep);
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 4) {
      if (data.h_e_qualification === "") {
        setw_h_e_qualification(true);
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 5) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 6) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 7) {
      if (data.p_bank === "") {
        setw_p_bank(true);
        setActiveStep(activeStep);
      } else if (data.p_amount === "") {
        setw_p_amount(true);
        setActiveStep(activeStep);
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 8) {
      if (data.s_type === "w_signature") {
        if (data.w_signature === "") {
          setw_w_signature(true);
          setActiveStep(activeStep);
        } else {
          setActiveStep(activeStep + 1);
        }
      } else if (data.s_type === "") {
        setw_s_type(true);
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === steps.length - 1) {
      setloader(true);
      // const test = data.bank_slip.name.replace(data.bank_slip.name, "testing.jpg");
      // console.log(test);
      // console.log(steps.length);

      const spic = new FormData();
      spic.append("spic", data.s_photo);

      const nicorpassport = new FormData();
      nicorpassport.append("nicorpassport", data.n_or_p_pic);

      const slip = new FormData();
      slip.append("slip", data.p_slip);

      const workfiles = new FormData();
      workfiles.append("workfiles", data.workcertificates);

      const ecertificates = new FormData();
      ecertificates.append("ecertificates", data.studycertificates);

      // const uploads_pic = async () => {
      //   let storageAccountName = "dm01";
      //   let sasToken =
      //     "sv=2021-06-08&ss=b&srt=sco&sp=rwlaciyx&se=2022-09-05T05:26:31Z&st=2022-09-04T21:26:31Z&spr=https,http&sig=w%2BX%2BOiKL931Cm7VIHj9fumGsqm%2BDSPlOkp8VkC9VPGM%3D";
      //   const blobService = new BlobServiceClient(
      //     `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
      //   );

      //   const containerClient = blobService.getContainerClient("test");

      //   const blobClient = containerClient.getBlobBatchClient(
      //     data.s_photo.name
      //   );

      //   const option = {
      //     blobHTTPHeaders: { blobContentType: data.s_photo.type },
      //   };

      //   await blobClient.uploadBrowserData(data.s_photo, option);
      // };

      // zip file generate for work files
      // for (let i = 0; i < data.workExperiencLetter.length; i++) {
      // for (let i = 0; i < data.cV.length; i++) {
      //   for (let i = 0; i < data.workPortfolio.length; i++) {
      // zip.file(
      //   data.workExperiencLetter[i].name,
      //   data.workExperiencLetter[i]
      // );
      // zip.file(data.cV[i].name, data.cV[i]);
      // zip.file(data.workPortfolio[i].name, data.workPortfolio[i]);
      // }
      //   }
      // }
      // const workfiles = new FormData();
      // zip.generateAsync({ type: "blob" }).then((content) => {
      //   workfiles.append("workfiles", content);
      // });

      // zip file generate for Education Certificates
      // for (let i = 0; i < data.oLCertificates.length; i++) {
      //   for (let i = 0; i < data.aLCertificates.length; i++) {
      //     for (let i = 0; i < data.iELTSCertificates.length; i++) {
      //       for (let i = 0; i < data.academicWorkPortfolio.length; i++) {
      //         for (let i = 0; i < data.otherCertificates.length; i++) {
      // zip.file(data.oLCertificates[i].name, data.oLCertificates[i]);
      //           zip.file(data.aLCertificates[i].name, data.aLCertificates[i]);
      //           zip.file(
      //             data.iELTSCertificates[i].name,
      //             data.iELTSCertificates[i]
      //           );
      //           zip.file(
      //             data.academicWorkPortfolio[i].name,
      //             data.academicWorkPortfolio[i]
      //           );
      //           zip.file(
      //             data.otherCertificates[i].name,
      //             data.otherCertificates[i]
      //           );
      //         }
      //       }
      // }
      //   }
      // }
      // const ecertificates = new FormData();
      // zip.generateAsync({ type: "blob" }).then((content) => {
      //   ecertificates.append("ecertificates", content);
      // });

      axios
        .get(`http://${Port}/request/requset/student/${data.n_o_p_number.trim()}`)
        .then((res) => {
          if (res.data === "") {
            axios.post(`http://${Port}/request/requset`, data).then((res) => {
              axios
                .post(`http://${Port}/upload/spic/${data.n_o_p_number}`, spic)
                .then((res) => {
                  axios
                    .post(
                      `http://${Port}/upload/nicorpassport/${data.n_o_p_number}`,
                      nicorpassport
                    )
                    .then((res) => {
                      axios
                        .post(
                          `http://${Port}/upload/slip/${data.n_o_p_number}`,
                          slip
                        )
                        .then((res) => {
                          axios
                            .post(
                              `http://${Port}/upload/workfiles/${data.n_o_p_number}`,
                              workfiles
                            )
                            .then((res) => {
                              axios
                                .post(
                                  `http://${Port}/upload/ecertificates/${data.n_o_p_number}`,
                                  ecertificates
                                )
                                .then((res) => {
                                  axios
                                    .post(
                                      `http://${Port}/request/request/subject`,
                                      data
                                    )
                                    .then((res) => {
                                      axios
                                        .post(
                                          `http://${Port}/request/student_requset`,
                                          data
                                        )
                                        .then((res) => {
                                          // console.log(res.data);
                                          setloader(false);
                                          setActiveStep(activeStep + 1);
                                          sets_datasend(true);
                                          setTimeout(() => {
                                            window.location.reload();
                                          }, 900);
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
          } else if (res.data !== "") {
            axios
              .get(
                `http://${Port}/request/requset/student/check/${data.n_o_p_number.trim()}`
              )
              .then((res) => {
                if (res.data !== "") {
                  axios
                    .post(`http://${Port}/request/requset`, data)
                    .then((res) => {
                      axios
                        .post(
                          `http://${Port}/upload/spic/${data.n_o_p_number}`,
                          spic
                        )
                        .then((res) => {
                          axios
                            .post(
                              `http://${Port}/upload/nicorpassport/${data.n_o_p_number}`,
                              nicorpassport
                            )
                            .then((res) => {
                              axios
                                .post(
                                  `http://${Port}/upload/slip/${data.n_o_p_number}`,
                                  slip
                                )
                                .then((res) => {
                                  axios
                                    .post(
                                      `http://${Port}/upload/workfiles/${data.n_o_p_number}`,
                                      workfiles
                                    )
                                    .then((res) => {
                                      axios
                                        .post(
                                          `http://${Port}/upload/ecertificates/${data.n_o_p_number}`,
                                          ecertificates
                                        )
                                        .then((res) => {
                                          axios
                                            .put(
                                              `http://${Port}/request/student_requset/reenter`,
                                              data
                                            )
                                            .then((res) => {
                                              setloader(false);
                                              setActiveStep(activeStep + 1);
                                              sets_datasend(true);
                                              setTimeout(() => {
                                                window.location.reload();
                                              }, 900);
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                } else {
                  alert("Please Check Your Profile");
                  setloader(false);
                }
              });
          }
        });
    }
    // else {
    //   setActiveStep(activeStep + 1);
    //   setSkippedSteps(
    //     skippedSteps.filter((skipItem) => skipItem !=== activeStep)
    //   );
    // }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setw_i_course(false);
    setw_f_name(false);
    setw_fi_name(false);
    setw_se_name(false);
    setw_name(false);
    setw_d_o_birth(false);
    setw_gender(false);
    setw_citizenship(false);
    setw_nic_o_passport(false);
    setw_nic(false);
    setw_ms_id(false);
    setw_passport(false);
    setw_email(false);
    setw_mobile(false);
    setw_p_address(false);
    setw_k_c_name(false);
    setw_k_c_mobile(false);
    setw_k_c_p_address(false);
    setw_k_c_relationship(false);
    setw_h_e_qualification(false);
    setw_p_bank(false);
    setw_p_amount(false);
    setw_s_type(false);
    setw_w_signature(false);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className="FromeBody">
      <div className="FromeCon">
        <Loader open={loader} />
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((step, index) => {
            const labelProps = {};
            const stepProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step {...stepProps} key={index}>
                <StepLabel {...labelProps}>{step}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <Typography variant="h3" align="center" marginTop={10}>
            Thank you for registering, we will get in touch with you shortly.
          </Typography>
        ) : (
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleNext)}>
                {getStepContent(activeStep)}
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleNext}
                  type="submit"
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  {/* {console.log(steps.length - 1)} */}
                </Button>
              </form>
            </FormProvider>
          </>
        )}
      </div>
      <Snackbar
        open={w_i_course}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Select Your Interested Course
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_f_name}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Full Name
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_fi_name}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          First name should be less than 20 characters
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_se_name}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Last name should be less than 30 characters
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_name}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Name With Initials
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_d_o_birth}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Select Your Date OF Birth
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_gender}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Select Your Gender
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_citizenship}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Citizenship
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_nic_o_passport}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Select NIC Or Passport
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_nic}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter The Correct NIC
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_ms_id}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your MS ID
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_passport}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Passport Number
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_email}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your E-mail Address
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_mobile}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Correct Mobile Number
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_p_address}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Permanent Address
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_k_c_name}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Next Of Kin Name
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_k_c_mobile}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Kin Mobile Number
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_k_c_p_address}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Kin Permanent Address
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_k_c_relationship}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Next Of Kin Relationship
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_h_e_qualification}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter Your Highest Educational Qualification
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_p_bank}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Select The Bank
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_p_amount}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter The Payment Amount
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_w_signature}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Enter The Your Signature
        </Alert>
      </Snackbar>
      <Snackbar
        open={w_s_type}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Select The Your Signature Type
        </Alert>
      </Snackbar>
      <Snackbar
        open={s_datasend}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Successfully Registered
        </Alert>
      </Snackbar>
    </div>
  );
}
