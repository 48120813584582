import React,{useState,useEffect} from "react";
import Alert from "@mui/material/Alert";

function Alerts(props) {
    const [active, setActive] = useState(false)
    useEffect(() => {
        setActive(true)
        setTimeout(() => {
            setActive(false)
            props.setActive(false)
        },3000)   
    },[props]) 
  return (
    // <Stack
    //   sx={{ width: "40%" }}
    //   spacing={2}
    //   style={{ position: "fixed", float: "right", marginTop: "10px" }}
    // >
    <div style={{width:"100%"}}>
      {active && (
        <ceneter>
          <Alert variant="filled" severity={props.type}>
            {props.message}
          </Alert>
        </ceneter>
      )}
    </div>
    // </Stack>
  );
}

export default Alerts