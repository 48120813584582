import React, { useEffect, useState } from "react";
import "../../Css/Admin/student.css";
import "../../Css/Admin/admin_home.css";
import AdminNavBar from "../../Components/Admin/admin_nav_bar";
import MobNavBar from "../../Components/Admin/mob_nav_bar";
import Header from "../../Components/Admin/header";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PortraitIcon from "@mui/icons-material/Portrait";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Port from "../../port";
import FileDownload from "js-file-download";
import ImageIcon from "@mui/icons-material/Image";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../Admin/Loader";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

function StudentsDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const nic = params.nic;
  const [detail, setDetails] = useState({});
  const [cBalance, setCBalance] = useState();
  const [bucketSubject, setBucketSubject] = useState({});
  const [uBalance, setUBalance] = useState();
  const [active, setActive] = useState(false);
  const [courseUpdateIsActive, setCourseUpdateIsActive] = useState(false);
  const [d_o_letter, setd_o_letter] = useState(false);
  const [s_o_letter, sets_o_letter] = useState(false);

  // offer letter variables
  const [email, setemail] = useState("");
  const [in_course, setin_course] = useState("");
  const [name, setname] = useState("");
  const [p_address, setp_address] = useState("");
  const [studentID, setstudentID] = useState("");
  const [c_duration, setc_duration] = useState("");
  const [sdate, setsdate] = useState("");
  const [edate, setedate] = useState("");
  const [efee, setefee] = useState("");
  const [ufee, setufee] = useState("");
  const [cfee, setcfee] = useState("");

  const paymentHistoryHandler = () => {
    navigate(`/payment/history/${nic}`);
  };

  async function functionOne() {
    await axios
      .get(`http://${Port}/admin/student/detail/${nic}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setDetails(res.data);
        setemail(res.data.email);
        setin_course(res.data.i_course);
        setname(res.data.name);
        setp_address(res.data.p_address);
        setstudentID(res.data.studentID);
        if (res.data.o_letter == "No") {
          setd_o_letter(true);
          sets_o_letter(false);
        } else if (res.data.o_letter =="") {
          setd_o_letter(true);
          sets_o_letter(false);
        } else {
          setd_o_letter(false);
          sets_o_letter(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`http://${Port}/admin/student/balance/university/payment/${nic}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setUBalance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`http://${Port}/admin/student/balance/course/payment/${nic}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setCBalance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function callFunction() {
    await setActive(true);
    await functionOne();
    await setActive(false);
  }

  useEffect(() => {
    callFunction();
  }, []);

  useEffect(() => {
    fetch(`http://${Port}/admin/course/student/${in_course}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // setcourse(data);
        setc_duration(data.c_duration);
        setsdate(data.s_date);
        setedate(data.e_date);
        setefee(data.e_fee);
        setufee(data.u_fee);
        setcfee(data.c_fee);
        // console.log(data.subject);
      });
  }, [in_course]);

  const profileImageHandler = () => {
    axios({
      url: `http://${Port}/admin/student/profile/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          // setOpen(false);
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        if (err) {
          alert("Something went wrong!");
        }
      });
  };

  const NICimageHandler = () => {
    axios({
      url: `http://${Port}/admin/student/nic/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          // setOpen(false);
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        if (err) {
          alert("Something went wrong!");
        }
      });
  };
  const eductionFilesHandler = () => {
    axios({
      url: `http://${Port}/admin/student/edu-detail/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.statusCode===404) {
          alert("No Documents")
        }
        else{
          FileDownload(res.data, `${nic}.rar`);
        }
      })
      .catch((err) => {
        if (err) {
          alert("Something went wrong!");
        }
      });
  };
  const workFilesHandler = () => {
    axios({
      url: `http://${Port}/admin/student/work-file/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.statusCode===404) {
          alert("No Documents")
        }
        else{
          FileDownload(res.data, `${nic}.rar`);
        }
      })
      .catch((err) => {
        if (err) {
          alert("Something went wrong!");
        }
      });
  };

  const studentRemoverHandler = () => {
    const confirmBox = window.confirm(
      "Are you sure want to remove this student?"
    );
    if (confirmBox === true) {
      axios
        .delete(`http://${Port}/admin/student/remove/${nic}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          alert(res.data.message);
          if (res.data.status === true) {
            alert(res.data.message);
            navigate(-1);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const courseUpdateHandler = () => {
    setCourseUpdateIsActive(true);
  };

  const Generate_Send_Offer_Letter = () => {
    setActive(true);
    let data = {
      email,
      nic,
      name,
      p_address,
      in_course,
      studentID,
      c_duration,
      sdate,
      edate,
      efee,
      ufee,
      cfee,
    };
    axios
      .put(`http://${Port}/admin/generate-offer-letter`, data, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data === true) {
          setActive(false);
          window.location.reload();
        } else {
          setActive(false);
        }
      });
  };

  const paymentSlipHandler = () => {
    axios({
      url: `http://${Port}/admin/student/payment-slip/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          // setOpen(false);
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  };

  const offerletterFilesHandler = () => {
    axios({
      url: `http://${Port}/admin/student/offer-letter/${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          FileDownload(res.data, `${nic}.pdf`);
        }
      })
      .catch((err) => {
        if (err) {
          alert("Something went wrong!");
        }
      });
  };
  return (
    <div className="container">
      <Loader open={active} />
      <div className="mob-navbar-wrapper">
        <MobNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>

        {courseUpdateIsActive && (
          <CourseUpdateForm
            setCourseUpdateIsActive={setCourseUpdateIsActive}
            token={token}
            nic={nic}
          />
        )}
        <div className="body-container">
          {/* ------------------------------------------------------ */}
          <div className="student-detail-wrapper clearfix">
            <ArrowBackIcon
              style={{ float: "left", marginTop: "20px", marginLeft: "20px" }}
              onClick={() => {
                navigate(-1);
              }}
            />

            <PersonRemoveIcon
              style={{
                float: "right",
                marginRight: "20px",
                marginLeft: "20px",
                marginTop: "20px",
                color: "red",
                cursor: "pointer",
              }}
              titleAccess="Remove user account"
              onClick={studentRemoverHandler}
            />
            <ImageIcon
              style={{
                float: "right",
                marginRight: "20px",
                marginTop: "20px",
                color: "black",
                cursor: "pointer",
              }}
              titleAccess="View student's Profile Picture"
              onClick={profileImageHandler}
            />
             <ReceiptIcon
              style={{
                float: "right",
                marginRight: "20px",
                marginTop: "20px",
                color: "black",
                cursor: "pointer",
              }}
              titleAccess="View payment slip"
              onClick={paymentSlipHandler}
            />
            <PortraitIcon
              style={{
                float: "right",
                marginRight: "20px",
                marginTop: "20px",
                color: "black",
                cursor: "pointer",
              }}
              titleAccess="View student's NIC"
              onClick={NICimageHandler}
            />
            <FileDownloadIcon
              style={{
                float: "right",
                marginRight: "10px",
                marginTop: "20px",
                color: "black",
                cursor: "pointer",
              }}
              titleAccess="Download Educational Files"
              onClick={eductionFilesHandler}
            />
            <FileDownloadIcon
              style={{
                float: "right",
                marginRight: "10px",
                marginTop: "20px",
                color: "black",
                cursor: "pointer",
              }}
              titleAccess="Download Work Files"
              onClick={workFilesHandler}
            />
          </div>
          {/* basic information section */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">
              Basic Information
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Student's First Name</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.fi_name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Student's Last Name</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.se_name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Student's Full Name</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.f_name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Name with Initials
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's {detail.nic_o_passport}
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={nic}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                {" "}
                Student's Date of Birth
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.d_o_birth}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Student's Gender</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.gender === "M" ? "Male" : "Female"}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"> Student's Citizenship</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.citizenship}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                {" "}
                Student's Permanent Address
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.p_address}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                {" "}
                Student's Correspondence Address
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.c_address}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                {" "}
                Student's MS Teams ID
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.ms_id}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          {/* contact information section */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">
              Contact Information
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Email Address
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.email}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Mobile Number
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.mobile}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"> Student's Land Number</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.l_line}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Facebook Link
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.fb_link}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                {" "}
                Student's Instagram Link
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.i_link}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's LinkedIn Link
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.l_i_link}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"> Student's Other Links</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.o_link}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          {/* Next Of Kins information section */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">
              Next of Kin Information
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Next of Kin's Name</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.k_c_name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Next of Kin's Mobile Number
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.k_c_mobile}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                {" "}
                Next of Kin's Land Number
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.k_c_l_line}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"> Next of Kin's Address</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.k_c_p_address}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                {" "}
                Next of Kin's Relationship
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.k_c_relationship}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          {/* Education information section */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">
              Education Information
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Hig Edu Qlification
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.h_e_qualification}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Student's IELTS Score</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.ielts_score}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"> Employment Details</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.e_details}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          {/* Bucket sub information section */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">Course Details</div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Student ID</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.studentID}
                  readOnly={true}
                  style={{ color: "red", outline: "none" }}
                />
              </div>
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Assigned Batch</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.b_name}
                  readOnly={true}
                  style={{ color: "red", outline: "none" }}
                />
              </div>
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"> Course Name </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.i_course}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"> Bucket Subjects </div>
              <div className="student-detail-input-wrapper">
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_1 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_1}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_2 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_2}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_3 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_3}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_4 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_4}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_5 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_5}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_6 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_6}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_7 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_7}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_8 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_8}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_9 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_9}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <h5 style={{ fontWeight: "500", fontSize: "14px" }}>
                  {detail.sub_10 ? (
                    <input
                      type="text"
                      className="student-detail-input"
                      value={detail.sub_10}
                      readOnly={true}
                    />
                  ) : (
                    ""
                  )}
                </h5>
                <div
                  className="update-course-btn"
                  onClick={courseUpdateHandler}
                >
                  Update Course
                </div>
              </div>
            </div>
          </div>

          {/* File information section */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">
              Document Information
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Acadamic Work Portfolio
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.academicWorkPortfolio}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's A/L Certificate
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.aLCertificates}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's O/L Certificate
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.oLCertificates}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Student's CV</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.cV}
                  readOnly={true}
                />
              </div>
            </div>{" "}
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's IELTS Certificate
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.iELTSCertificates}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Other Certificate
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.otherCertificates}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Working Experience Letter
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.workExperiencLetter}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Work Portfolio
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="checkbox"
                  checked={String(1) === detail.workPortfolio}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          {/* Payment information section */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">
              Payment Information
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Student's Interested Course
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.i_course}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Course Fee</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={"Rs " + parseInt(detail.c_fee).toFixed(2)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">University Fee</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={"Rs " + (165000).toFixed(2)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Paid Course Fee</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={"Rs " + parseInt(cBalance).toFixed(2)}
                  readOnly={true}
                  style={{ color: "green" }}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Paid University Fee</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={"Rs " + parseInt(uBalance).toFixed(2)}
                  readOnly={true}
                  style={{ color: "green" }}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Balance Course Fee</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={
                    "Rs " +
                    (parseInt(detail.c_fee) - parseInt(cBalance)).toFixed(2)
                  }
                  readOnly={true}
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Balance University Fee</div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={"Rs " + parseInt(165000 - uBalance).toFixed(2)}
                  readOnly={true}
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">Balance University Fee</div>
              <div className="student-detail-input-wrapper">
                <div className="history-btn" onClick={paymentHistoryHandler}>
                  Payment History
                </div>
              </div>
            </div>
          </div>
          {/* Offer letter information */}
          <div className="student-detail-wrapper clearfix">
            <div className="student-detail-topic-wrapper">
              Offer Letter Information
            </div>

            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable">
                Did You Send the Student Offer Letter
              </div>
              <div className="student-detail-input-wrapper">
                <input
                  type="text"
                  className="student-detail-input"
                  value={detail.o_letter}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="student-detail-body-wrapper">
              <div className="student-detail-lable"></div>
              <div className="student-detail-input-wrapper">
                <div className="offer-letter-btn">
                  <Button
                    variant="contained"
                    color="error"
                    disabled={d_o_letter}
                    onClick={offerletterFilesHandler}
                  >
                    Download Offer Letter
                  </Button>
                </div>
                <>
                  {detail.o_letter == "Yes" ? (
                    <div className="offer-letter-btn">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={Generate_Send_Offer_Letter}
                      >
                        Re-Send Offer Letter
                      </Button>
                    </div>
                  ) : (
                    <div className="offer-letter-btn">
                      <Button
                        variant="contained"
                        disabled={s_o_letter}
                        color="success"
                        onClick={Generate_Send_Offer_Letter}
                      >
                        Send Offer Letter
                      </Button>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

function CourseUpdateForm(props) {
  const [subjects, setSubjects] = useState([]);
  const [buckets, setBuckets] = useState([]);
  const [newSubject, setNewSubject] = useState("");

  const [bucketSubjects, setBucketSubjects] = useState({
    sub1: "",
    sub2: "",
    sub3: "",
    sub4: "",
    sub5: "",
    sub6: "",
    sub7: "",
    sub8: "",
    sub9: "",
    sub10: "",
  });

  function handleChange(evt) {
    const { name, value } = evt.target;
    setBucketSubjects({
      ...bucketSubjects,
      [name]: value,
    });
  }

  const updateHandler = () => {
    console.log(props.nic);
    const confirmBox = window.confirm("Are you sure want to update?");
    if (confirmBox === true) {
      axios
        .put(
          `http://${Port}/admin/bucket-subject/details/update/${props.nic}/${newSubject}`,
          bucketSubjects,
          {
            headers: {
              Authorization: `bearer ${props.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status === false) {
            alert(res.data.message);
          } else {
            alert(res.data.message);
            setTimeout(() => {
              props.setCourseUpdateIsActive(false);
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeHandler = (e) => {
    if (e.target.value !== "none") {
      let subject = e.target.value;
      setNewSubject(subject);

      axios
        .get(`http://${Port}/admin/bucket-subject/details/${subject}`, {
          headers: {
            Authorization: `bearer ${props.token}`,
          },
        })
        .then((res) => {
          if (res.status === false) {
            alert(res.message);
          } else {
            setBuckets(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`http://${Port}/admin/courses`, {
        headers: {
          Authorization: `bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res.status === false) {
          alert(res.message);
        } else {
          setSubjects(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="course-form-container">
      <div className="course-form-header">
        <h3>Update Bucket Subjects</h3>
        <CloseIcon
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => {
            props.setCourseUpdateIsActive(false);
          }}
        />
      </div>
      <div className="course-details-body-wrapper">
        <div className="select-container">
          <label className="course-detail-lable">Select an course</label>
          <select className="course-detail-input" onChange={onChangeHandler}>
            <option value="none">None</option>
            {subjects.map((subject, index) => (
              <option value={subject.c_name}>{subject.c_name}</option>
            ))}
          </select>
        </div>
        <br />
        {buckets.map((bucket, index) => (
          <div className="select-bucket-container">
            <label className="course-detail-lable">
              Select {++index} optional subject
            </label>
            <select
              className="course-detail-input"
              name={"sub" + index}
              onChange={handleChange}
            >
              <option value="">None</option>
              {buckets.map((bucket) => (
                <option value={bucket.sub_name}>{bucket.sub_name}</option>
              ))}
            </select>
          </div>
        ))}
        <div className="course-update-btn" onClick={updateHandler}>
          Update
        </div>
      </div>
    </div>
  );
}

export default StudentsDetail;
