import React from "react";
import "../../Css/Admin/reject_message.css";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";

function RejectMessage(props) {
    const closeHandler = () => {
        props.setPopupActive(false);
    }
  return (
    <div className="popup-container">
      <div className="popup-wrapper">
        <DoDisturbAltIcon
          onClick={closeHandler}
          style={{
            float: "right",
            marginRight: "10px",
            marginTop: "10px",
            color: "red",
          }}
        />
        <div className="message-box-wrapper">
          <center>
            <p className="mes-box-lable">
              Please enter a valid reason for continue the process.
            </p>
            <br />
            <textarea
              id="w3review"
              name="w3review"
              rows="5"
              className="message-box"
              placeholder="Reason..."
              onChange={(e) => {
                props.setRejectMes(e.target.value);
              }}
            />
          </center>
        </div>
        <div className="action-btn-wrapper">
          <button
            className="reject-btn"
            style={{ float: "right" }}
            onClick={props.rejectHandler}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              {" "}
              <p style={{ marginTop: "3px" }}>Reject</p>
              <DoDisturbAltIcon fontSize="small" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default RejectMessage;
