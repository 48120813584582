import React, { useState } from "react";
import "../../Css/Admin/mob_nav_bar.css";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import { NavLink } from "react-router-dom";

import AccountMenu from "./profile";

function MonNavBar() {
  const [active, setActive] = useState(false);
  const position = localStorage.getItem("position");
  const navBarHandler = () => {
    setActive(!active);
  };
  return (
    <div className="mob-nav-container">
      <div className="mob-nav-header clearfix">
        <div className="mob-nav-header-logo">
          <button className="menu-btn" onClick={navBarHandler}>
            <MenuIcon fontSize="large" />
          </button>
        </div>
        <div className="mob-nav-header-btn">
          <AccountMenu />
        </div>
      </div>
      {active && (
        <div className="mob-nav-body">
          <div className="mob-nav-body-links"></div>
          <NavLink to="/dashboard" className="navbar-link">
            <div className="mob-nav-body-links">
              <HomeOutlinedIcon />
              <p>Dashboard</p>
            </div>
          </NavLink>
          {position === "Administrator" || position === "Manager" ? (
            <>
              <NavLink to="/student-request" className="navbar-link">
                <div className="mob-nav-body-links">
                  <MarkUnreadChatAltOutlinedIcon />
                  <p>Pending Requests</p>
                </div>
              </NavLink>
              <NavLink to="/recent/student/payment" className="navbar-link">
                <div className="mob-nav-body-links">
                  <CheckCircleOutlineOutlinedIcon />
                  <p>Pending Payments</p>
                </div>
              </NavLink>
            </>
          ) : null}
          {position === "Administrator" || position === "Manager" ? (
            <>
              <NavLink to="/approved-request" className="navbar-link">
                <div className="mob-nav-body-links">
                  <DoDisturbAltOutlinedIcon />
                  <p>Approved Requests</p>
                </div>
              </NavLink>
              <NavLink to="/reject-request" className="navbar-link">
                <div className="mob-nav-body-links">
                  <PlaylistAddIcon />
                  <p>Rejected Requests</p>
                </div>
              </NavLink>
            </>
          ) : null}
          {position === "Administrator" || position === "Manager" ? (
            <>
              <NavLink to="/approved/payment" className="navbar-link">
                <div className="mob-nav-body-links">
                  <CreditScoreIcon />
                  <p>Approved Payments</p>
                </div>
              </NavLink>
              <NavLink to="/rejected/payment" className="navbar-link">
                <div className="mob-nav-body-links">
                  <CreditCardOffIcon />
                  <p>Rejected Payments</p>
                </div>
              </NavLink>
            </>
          ) : null}
          <NavLink to="/current/student" className="navbar-link">
            <div className="mob-nav-body-links">
              <PersonOutlineOutlinedIcon />
              <p>Students</p>
            </div>
          </NavLink>
          <NavLink to="/assigngroup" className="navbar-link">
            <div className="mob-nav-body-links">
              <GroupsIcon />
              <p>Assign Group</p>
            </div>
          </NavLink>
          {position === "Administrator" || position === "Manager" ? (
            <>
              <NavLink to="/course" className="navbar-link">
                <div className="mob-nav-body-links">
                  <PlaylistAddIcon />
                  <p>Add Course</p>
                </div>
              </NavLink>
            </>
          ) : null}
          {position === "Administrator" ? (
            <>
              <NavLink to="/addusers" className="navbar-link">
                <div className="mob-nav-body-links">
                  <GroupAddIcon />
                  <p>Add User</p>
                </div>
              </NavLink>
            </>
          ) : null}
          {position === "Administrator" || position === "Manager" ? (
            <>
              <NavLink to="/addbatch" className="navbar-link">
                <div className="mob-nav-body-links">
                  <Diversity3Icon />
                  <p>Add Batch</p>
                </div>
              </NavLink>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default MonNavBar;
