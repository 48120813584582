import React, { useState, useEffect } from "react";
import "../../Css/Admin/request-details.css";
import MonNavBar from "./mob_nav_bar";
import AdminNavBar from "./admin_nav_bar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import PortraitIcon from "@mui/icons-material/Portrait";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Header from "./header";
import Port from "../../port.js";
import axios from "axios";
import Alerts from "./Alert";
import FileDownload from "js-file-download";
import RejectMessage from "./reject_message";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../../Components/Admin/Loader";

function RequestDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [sID, setStudentID] = useState("");
  const [detail, setDetails] = useState({});
  const [active, setActive] = useState(false);
  const [ertype, setErtype] = useState();
  const [ermes, setErmes] = useState();
  const [popupActive, setPopupActive] = useState(false);
  const [rejectMes, setRejectMes] = useState();
  let nic = detail.n_o_p_number;
  const token = localStorage.getItem("token");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // const [data, setdata] = useState([]);
  const [ol, setol] = useState([]);
  const [al, setal] = useState([]);
  const [ielts, setielts] = useState([]);
  const [academic_w_p, setacademic_w_p] = useState([]);
  const [oc, setoc] = useState([]);
  const [cv, setcv] = useState([]);
  const [workexperiencletter, setworkexperiencletter] = useState([]);
  const [workportfolio, setworkportfolio] = useState([]);

  const [checkol, setcheckol] = useState(false);
  const [checkal, setcheckal] = useState(false);
  const [checkielts, setcheckielts] = useState(false);
  const [checkacademic_w_p, setcheckacademic_w_p] = useState(false);
  const [checkoc, setcheckoc] = useState(false);
  const [checkcv, setcheckcv] = useState(false);
  const [checkworkexperiencletter, setcheckworkexperiencletter] =
    useState(false);
  const [checkworkportfolio, setcheckworkportfolio] = useState(false);

  const [loader, setloader] = useState(false);
  useEffect(() => {
    axios
      .get(`http://${Port}/admin/student/request/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setDetails(res.data);
      })
      .catch((err) => {
        setActive(true);
        setErmes("Something went wrong!");
        setErtype("error");
      });
  }, [id, token]);

  const profileImageHandler = () => {
    axios({
      url: `http://${Port}/admin/student/profile/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          // setOpen(false);
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };

  const NICimageHandler = () => {
    axios({
      url: `http://${Port}/admin/student/nic/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          // setOpen(false);
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };
  const eductionFilesHandler = () => {
    axios({
      url: `http://${Port}/admin/student/edu-detail/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.statusCode===404) {
          alert("No Documents")
        }
        else{
          FileDownload(res.data, `${nic}.rar`);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };

  const workFileFilesHandler = () => {
    axios({
      url: `http://${Port}/admin/student/work-file/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.statusCode===404) {
          alert("No Documents")
        }
        else{
          FileDownload(res.data, `${nic}.rar`);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };

  const paymentSlipHandler = () => {
    axios({
      url: `http://${Port}/admin/student/payment-slip/nic-${nic}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data) {
          // setOpen(false);
          const file = new Blob([res.data], { type: "image/jpg" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((err) => {
        if (err) {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
        }
      });
  };

  const approveHandler = () => {
    if (sID.trim().length === 0) {
      alert("Please assign an ID");
    } else {
      const confirmBox = window.confirm(
        "Are you sure want to approve this request?"
      );
      if (confirmBox === true) {
        setloader(true);
        detail.aLCertificates = checkal;
        detail.academicWorkPortfolio = checkacademic_w_p;
        detail.cV = checkcv;
        detail.iELTSCertificates = checkielts;
        detail.oLCertificates = checkol;
        detail.otherCertificates = checkoc;
        detail.workExperiencLetter = checkworkexperiencletter;
        detail.workPortfolio = checkworkportfolio;

        axios
          .post(
            `http://${Port}/admin/aproved/detail/save/nic-${nic}/id-${id}/sid-${sID}`,
            detail,
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data === false) {
              setActive(true);
              setErmes("Something went wrong!");
              setErtype("error");
            } else {
              if (res.data === true) {
                setActive(true);
                setErmes("This user is already registered!");
                setErtype("warning");
              } else {
                setActive(true);
                setErmes(res.data);
                setloader(false);
                setErtype("success");
                setTimeout(() => {
                  navigate("/approved-request");
                }, 2000);
              }
            }
          })
          .catch((err) => {
            setActive(true);
            setloader(false);
            setErmes("Something went wrong!");
            setErtype("error");
            console.log(err);
          });
      }
    }
  };

  const rejectHandler = () => {
    setPopupActive(false);
    setTimeout(() => {
      detail.r_message = rejectMes;
      axios
        .post(
          `http://${Port}/admin/reject/detail/save/id-${id}/nic-${nic}`,
          detail,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data === false) {
            setActive(true);
            setErmes("Something went wrong!");
            setErtype("error");
          } else {
            setActive(true);
            setErmes(res.data);
            setErtype("success");
            setTimeout(() => {
              navigate("/reject-request");
            }, 2000);
          }
        })
        .catch((err) => {
          setActive(true);
          setErmes("Something went wrong!");
          setErtype("error");
          console.log(err);
        });
    }, 500);
  };
  const popupHandler = () => {
    setPopupActive(true);
  };
  useEffect(() => {
    axios
      .get(`http://${Port}/admin/student/doc/${id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setol(res.data.oLCertificates);
        setal(res.data.aLCertificates);
        setielts(res.data.iELTSCertificates);
        setacademic_w_p(res.data.academicWorkPortfolio);
        setoc(res.data.otherCertificates);
        setcv(res.data.cV);
        setworkexperiencletter(res.data.workExperiencLetter);
        setworkportfolio(res.data.workPortfolio);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, id]);
  return (
    <div className="container">
      <Loader open={loader} />
      {popupActive && (
        <RejectMessage
          setRejectMes={setRejectMes}
          setPopupActive={setPopupActive}
          rejectHandler={rejectHandler}
        />
      )}
      <div className="mob-navbar-wrapper">
        <MonNavBar />
      </div>
      <div className="navbar-wrapper">
        <AdminNavBar />
      </div>
      <div className="body-wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="body-container">
          {/* ------------------------------------------------------ */}

          <div className="body-details-wrapper clearfix">
            <ArrowBackIcon
              className="back-btn"
              onClick={() => {
                navigate(-1);
              }}
            />
            <ReceiptIcon
              className="action-icons"
              titleAccess="View payment slip"
              onClick={paymentSlipHandler}
            />

            <PortraitIcon
              className="action-icons"
              titleAccess="View student's NIC"
              onClick={NICimageHandler}
            />
            <FileDownloadIcon
              className="action-icons"
              titleAccess="Download work files"
              onClick={workFileFilesHandler}
            />
            <FileDownloadIcon
              className="action-icons"
              titleAccess="Download educational files"
              onClick={eductionFilesHandler}
            />

            <div className="student-request-details-header-wrapper">
              <div className="student-request-id">
                Request ID : <b>{detail.id ? detail.id : "-"}</b>
              </div>
              <div className="student-image">
                <img
                  src={`http://${Port}/profile/${nic}.jpg`}
                  alt="Profile"
                  className="student-profile-icon"
                  onClick={profileImageHandler}
                />
              </div>
              <div className="student-request-timedate">
                Requested Date : <b>{detail.s_date ? detail.s_date : "-"}</b>
              </div>
            </div>
            <div className="student-request-details-body-wrapper ">
              {/* basic informations below */}
              <div className="student-request-details-topic">
                - Basic Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper ">
                    <p className="student-question">Full Name </p>
                    {/* <p className="student-question">First Name </p>
                    <p className="student-question">Last Name </p> */}
                    <p className="student-question">Name with Initials </p>
                    <p className="student-question">Date of Birth </p>
                    <p className="student-question">Gender </p>
                    <p className="student-question">County </p>
                    <p className="student-question">
                      {detail.nic_o_passport ? detail.nic_o_passport : "-"}
                    </p>
                    <p className="student-question">MS Teams ID </p>
                    <p className="student-question">Per Address </p>
                    <p className="student-question">
                      Corr Address
                      {/* <br />
                      <span className="question">Correspondence Address</span> */}
                    </p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.f_name ? detail.f_name : "-"}
                      {/* <br />
                      <span className="answer">
                        Mahabalage Don Thivanka Saparamaduuuuuuuuuuuuu
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {/* {detail.fi_name ? detail.f_name : "-"} */}
                      {/* <br />
                      <span className="answer">
                        Mahabalage Don Thivanka Saparamaduuuuuuuuuuuuu
                      </span> */}
                    {/* </p>
                    <p className="student-answer">
                      {detail.f_name ? detail.f_name : "-"} */}
                      {/* <br />
                      <span className="answer">
                        Mahabalage Don Thivanka Saparamaduuuuuuuuuuuuu
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.name ? detail.name : "-"}
                      {/* <br />
                      <span className="answer">
                        Mahabalage Don Thivanka Saparamaduuuuuuuuuuuuu
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.d_o_birth ? detail.d_o_birth : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.gender ? detail.gender : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.citizenship ? detail.citizenship : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.n_o_p_number ? detail.n_o_p_number : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.ms_id ? detail.ms_id : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.p_address ? detail.p_address : "-"}
                      {/* <br />
                      <span className="answer">
                        No 21,Saparamadu Place, Waragoda, Kelaniya,Sri Lanka
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.c_address ? detail.c_address : "-"}
                      {/* <br />
                      <span className="answer">
                        No 21,Saparamadu Place, Waragoda, Kelaniya,Sri Lanka
                      </span> */}
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Email Address</p>
                    <p className="student-question">Mobile Number</p>
                    <p className="student-question">Land Number</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.email ? detail.email : "-"}
                      {/* <br />
                      <span className="answer">
                        thivankasaparamadu111@gmail.com
                      </span> */}
                    </p>
                    <p className="student-answer">
                      {detail.mobile ? detail.mobile : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.l_line ? detail.l_line : "-"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Next of Kin & Education Information below */}
              <div className="student-request-details-topic">
                - Next of Kin & Education Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Name</p>
                    <p className="student-question">Mobile Number</p>
                    <p className="student-question"> Land Number</p>
                    <p className="student-question">Address </p>
                    <p className="student-question"> Relationship</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.k_c_name ? detail.k_c_name : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_mobile ? detail.k_c_mobile : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_l_line ? detail.k_c_l_line : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_p_address ? detail.k_c_p_address : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.k_c_relationship ? detail.k_c_relationship : "-"}
                    </p>
                  </div>
                </div>

                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Hig Edu Qlification</p>
                    <p className="student-question">IELTS Score</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.h_e_qualification
                        ? detail.h_e_qualification
                        : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.ielts_score ? detail.ielts_score : "-"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Social Profile Links & Employment Information below */}
              <div className="student-request-details-topic">
                - Social Profile Links & Employment Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Facebook Account</p>
                    <p className="student-question">Instagram Account</p>
                    <p className="student-question"> Linkedin Account</p>
                    <p className="student-question">Other Account </p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {" "}
                      {detail.fb_link ? detail.fb_link : "-"}{" "}
                    </p>
                    <p className="student-answer">
                      {" "}
                      {detail.i_link ? detail.i_link : "-"}
                    </p>
                    <p className="student-answer">
                      {" "}
                      {detail.l_i_link ? detail.l_i_link : "-"}
                    </p>
                    <p className="student-answer">
                      {" "}
                      {detail.o_link ? detail.o_link : "-"}
                    </p>
                  </div>
                </div>

                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Employment Details</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {" "}
                      {detail.e_details ? detail.e_details : "-"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Payment and personal statement below */}
              <div className="student-request-details-topic">
                - Payment & Personal statement Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question"> Bank Name</p>
                    <p className="student-question">Payment Amount</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {" "}
                      {detail.p_bank ? detail.p_bank : "-"}
                    </p>
                    <p className="student-answer">
                      Rs {detail.p_amount ? detail.p_amount : "-"}
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper"></div>
              </div>

              {/* Subject Viewer */}
              {/* Payment and personal statement below */}
              <div className="student-request-details-topic">
                - Subject Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Subject 1</p>
                    <p className="student-question">Subject 2</p>
                    <p className="student-question">Subject 3</p>
                    <p className="student-question">Subject 4</p>
                    <p className="student-question">Subject 5</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {" "}
                      {detail.sub_1 ? detail.sub_1 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_2 ? detail.sub_2 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_3 ? detail.sub_3 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_4 ? detail.sub_4 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_5 ? detail.sub_5 : "-"}
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Subject 6</p>
                    <p className="student-question">Subject 7</p>
                    <p className="student-question">Subject 8</p>
                    <p className="student-question">Subject 9</p>
                    <p className="student-question">Subject 10</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <p className="student-answer">
                      {detail.sub_6 ? detail.sub_6 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_7 ? detail.sub_7 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_8 ? detail.sub_8 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_9 ? detail.sub_9 : "-"}
                    </p>
                    <p className="student-answer">
                      {detail.sub_10 ? detail.sub_10 : "-"}
                    </p>
                  </div>
                </div>
              </div>

              {/* document  below */}
              <div className="student-request-details-topic">
                - Document Information -
              </div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper-doc">
                    <p className="student-question-doc"> O/L Certificate </p>
                    <p className="student-question-doc">A/L Certificate</p>
                    <p className="student-question-doc">IELTS Certificate</p>
                    <p className="student-question-doc">
                      Academic Work/Portfolio Certificate
                    </p>
                    <p className="student-question-doc">Other Certificates</p>
                    <p className="student-question-doc1">
                      Work Experience Letter
                    </p>
                    <p className="student-question-doc1">CV</p>
                    <p className="student-question-doc1">Work Portfolio</p>
                  </div>
                  <div className="student-answer-wrapper-doc">
                    <p className="student-answer-doc">
                      {" "}
                      {ol === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                    <p className="student-answer-doc">
                      {al === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                    <p className="student-answer-doc">
                      {ielts === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                    <p className="student-answer-doc">
                      {academic_w_p === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                    <p className="student-answer-doc">
                      {oc === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                    <p className="student-answer-doc">
                      {workexperiencletter === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                    <p className="student-answer-doc">
                      {cv === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                    <p className="student-answer-doc">
                      {workportfolio === "" ? (
                        <Checkbox {...label} size="small" disabled />
                      ) : (
                        <Checkbox {...label} size="small" disabled checked />
                      )}
                    </p>
                  </div>
                  <div className="student-answer-wrapper-doc2">
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkol}
                        onChange={(e) => {
                          setcheckol(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkal}
                        onChange={(e) => {
                          setcheckal(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkielts}
                        onChange={(e) => {
                          setcheckielts(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkacademic_w_p}
                        onChange={(e) => {
                          setcheckacademic_w_p(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkoc}
                        onChange={(e) => {
                          setcheckoc(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkworkexperiencletter}
                        onChange={(e) => {
                          setcheckworkexperiencletter(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkcv}
                        onChange={(e) => {
                          setcheckcv(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                    <p className="student-answer-doc">
                      <Checkbox
                        {...label}
                        value={checkworkportfolio}
                        onChange={(e) => {
                          setcheckworkportfolio(e.target.checked);
                        }}
                        size="small"
                      />
                    </p>
                  </div>
                </div>
                <div className="student-contact-details-wrapper"></div>
              </div>

              {/* Student ID below */}
              <div className="student-request-details-topic"></div>
              <div className="student-request-details-body-section1">
                <div className="student-personal-details-wrapper">
                  <div className="student-question-wrapper">
                    <p className="student-question">Assign new ID</p>
                  </div>
                  <div className="student-answer-wrapper">
                    <TextField
                      label="Student's ID"
                      id="outlined-size-small"
                      size="small"
                      onChange={(e) => {
                        setStudentID(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="action-button-wrapper clearfix">
                <div className="alert-wrapper">
                  {active && (
                    <Alerts
                      message={ermes}
                      type={ertype}
                      setActive={setActive}
                    />
                  )}
                </div>
                <div className="btn-wrapper">
                  <button className="reject-btn" onClick={popupHandler}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      <p style={{ marginTop: "3px" }}>Reject</p>
                      <DoDisturbAltIcon fontSize="small" />
                    </div>
                  </button>

                  <button className="approve-btn" onClick={approveHandler}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      <p style={{ marginTop: "3px" }}>Approve</p>
                      <CheckCircleOutlineIcon fontSize="small" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

export default RequestDetails;
